import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom'
import { getDatabase, ref as dbref, runTransaction, set, child, get } from "firebase/database";
import { initializeApp } from "firebase/app";
import moment from 'moment'

import { UserAuth } from '../context/AuthContext'

// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';



const firebaseConfig = {
  apiKey: "AIzaSyBhHLgOLj4StzNAGDJ4el1pOPePWzt-w3g",
  authDomain: "advice-cf528.firebaseapp.com",
  databaseURL: "https://advice-cf528-default-rtdb.firebaseio.com",
  projectId: "advice-cf528",
  storageBucket: "advice-cf528.appspot.com",
  messagingSenderId: "835498848483",
  appId: "1:835498848483:web:1f1d60736063ffb5a215d6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);



const rtdb = getDatabase(app);

const data = [
  {
      "id": "51000011",
      "branch": "Pantip Plaza Ngamwongwan",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000014",
      "branch": "IT Mall Fortune Town",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000018",
      "branch": "Imperial Samrong",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000028",
      "branch": "Central Rama 2",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000034",
      "branch": "Robinson Ratchaphruek ",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000038",
      "branch": "Central Westville",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000012",
      "branch": "Central Westgate",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000013",
      "branch": "Seacon Bang Khae",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000019",
      "branch": "Seacon Srinakarin",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000035",
      "branch": "Central World",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000021",
      "branch": " The Forrest",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000033",
      "branch": "Central Rama 3",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000017",
      "branch": "Zeer Rangsit",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000022",
      "branch": "Fashion Island",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000029",
      "branch": "Mega Bangna",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000002",
      "branch": "Prachin Buri",
      "region": "AVI - East"
  },
  {
      "id": "51000003",
      "branch": "Chachoengsao",
      "region": "AVI - East"
  },
  {
      "id": "51000008",
      "branch": "Chonburi/Atara Mall",
      "region": "AVI - East"
  },
  {
      "id": "51000037",
      "branch": "Chonburi/Harbor Mall Laem Chabang",
      "region": "AVI - East"
  },
  {
      "id": "51000009",
      "branch": "Tukcom Chonburi",
      "region": "AVI - East"
  },
  {
      "id": "51000026",
      "branch": "Chonburi/Ban Suan Intersection",
      "region": "AVI - East"
  },
  {
      "id": "51000010",
      "branch": "Chonburi/Pattaya",
      "region": "AVI - East"
  },
  {
      "id": "51000036",
      "branch": "Tukcom Pattaya",
      "region": "AVI - East"
  },
  {
      "id": "51000001",
      "branch": "Chanthaburi",
      "region": "AVI - East"
  },
  {
      "id": "51000031",
      "branch": "Central Chanthaburi",
      "region": "AVI - East"
  },
  {
      "id": "51000004",
      "branch": "Sa Kaeo",
      "region": "AVI - East"
  },
  {
      "id": "51000023",
      "branch": "Central Rayong",
      "region": "AVI - East"
  },
  {
      "id": "51000025",
      "branch": " Rayong",
      "region": "AVI - East"
  },
  {
      "id": "51000006",
      "branch": "Trat",
      "region": "AVI - East"
  },
  {
      "id": "52000045",
      "branch": "Lopburi/Sa Kaeo Circle",
      "region": "UNS - Central"
  },
  {
      "id": "52000046",
      "branch": "Lopburi/Phra Narai",
      "region": "UNS - Central"
  },
  {
      "id": "52000047",
      "branch": "Sing Buri",
      "region": "UNS - Central"
  },
  {
      "id": "52000050",
      "branch": "Chai Nat",
      "region": "UNS - Central"
  },
  {
      "id": "52000078",
      "branch": "Ayutthaya",
      "region": "UNS - Central"
  },
  {
      "id": "52000082",
      "branch": "Saraburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000083",
      "branch": "Suphan Buri",
      "region": "UNS - Central"
  },
  {
      "id": "52000001",
      "branch": "Nakhon Pathom",
      "region": "UNS - Central"
  },
  {
      "id": "52000092",
      "branch": "Central Nakhon Pathom",
      "region": "UNS - Central"
  },
  {
      "id": "52000048",
      "branch": "Kanchanaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000049",
      "branch": "Phetchaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000080",
      "branch": "Prachuap Khiri Khan",
      "region": "UNS - Central"
  },
  {
      "id": "52000085",
      "branch": "Ratchaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000084",
      "branch": "Central Mahachai",
      "region": "UNS - Central"
  },
  {
      "id": "52000003",
      "branch": "Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000094",
      "branch": "Icon IT Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000097",
      "branch": "Central Festival Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000004",
      "branch": "Lamphun",
      "region": "UNS - North"
  },
  {
      "id": "52000006",
      "branch": "Chiang Rai",
      "region": "UNS - North"
  },
  {
      "id": "52000099",
      "branch": "Central Chiang Rai",
      "region": "UNS - North"
  },
  {
      "id": "52000007",
      "branch": "Chiang Rai/Mae Sai",
      "region": "UNS - North"
  },
  {
      "id": "52000008",
      "branch": "Lampang",
      "region": "UNS - North"
  },
  {
      "id": "52000009",
      "branch": "Phrea",
      "region": "UNS - North"
  },
  {
      "id": "52000010",
      "branch": "Nan",
      "region": "UNS - North"
  },
  {
      "id": "52000011",
      "branch": "Phayao",
      "region": "UNS - North"
  },
  {
      "id": "52000012",
      "branch": "Uttaradit",
      "region": "UNS - North"
  },
  {
      "id": "52000013",
      "branch": "Sukhothai",
      "region": "UNS - North"
  },
  {
      "id": "52000018",
      "branch": "Phichit",
      "region": "UNS - North"
  },
  {
      "id": "52000019",
      "branch": "Phichit/Taphan Hin",
      "region": "UNS - North"
  },
  {
      "id": "52000020",
      "branch": "Phitsanulok",
      "region": "UNS - North"
  },
  {
      "id": "52000061",
      "branch": "Phetchabun",
      "region": "UNS - North"
  },
  {
      "id": "52000015",
      "branch": "Kamphaeng Phet",
      "region": "UNS - North"
  },
  {
      "id": "52000016",
      "branch": "Nakhon Sawan",
      "region": "UNS - North"
  },
  {
      "id": "52000062",
      "branch": "Central Nakhon Sawan",
      "region": "UNS - North"
  },
  {
      "id": "52000017",
      "branch": "Uthai Thani",
      "region": "UNS - North"
  },
  {
      "id": "52000021",
      "branch": "Tak",
      "region": "UNS - North"
  },
  {
      "id": "52000052",
      "branch": "Krabi",
      "region": "UNS - South"
  },
  {
      "id": "52000058",
      "branch": "Nakhon Si Thammarat/Thung Song",
      "region": "UNS - South"
  },
  {
      "id": "52000059",
      "branch": "Nakhon Si Thammarat",
      "region": "UNS - South"
  },
  {
      "id": "52000088",
      "branch": "Central Nakhon Si Thammarat",
      "region": "UNS - South"
  },
  {
      "id": "52000090",
      "branch": "Trang",
      "region": "UNS - South"
  },
  {
      "id": "52000051",
      "branch": "Phatthalung",
      "region": "UNS - South"
  },
  {
      "id": "52000054",
      "branch": "Songkhla/Hat Yai",
      "region": "UNS - South"
  },
  {
      "id": "52000096",
      "branch": "Central Hat Yai",
      "region": "UNS - South"
  },
  {
      "id": "52000055",
      "branch": "Songkhla",
      "region": "UNS - South"
  },
  {
      "id": "52000093",
      "branch": "Satun",
      "region": "UNS - South"
  },
  {
      "id": "52000053",
      "branch": "Ranong",
      "region": "UNS - South"
  },
  {
      "id": "52000057",
      "branch": "Phuket",
      "region": "UNS - South"
  },
  {
      "id": "52000098",
      "branch": "Central Phuket Festival",
      "region": "UNS - South"
  },
  {
      "id": "52000060",
      "branch": "ชุมพร ",
      "region": "UNS - South"
  },
  {
      "id": "52000086",
      "branch": "Surat Thani",
      "region": "UNS - South"
  },
  {
      "id": "52000087",
      "branch": "Central Suratthani",
      "region": "UNS - South"
  },
  {
      "id": "52000100",
      "branch": "Pattani",
      "region": "UNS - South"
  },
  {
      "id": "52000101",
      "branch": "Narathiwat",
      "region": "UNS - South"
  },
  {
      "id": "52000024",
      "branch": "Udon Thani",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000069",
      "branch": "Tukcom Udon Thani",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000064",
      "branch": "Nong Khai",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000066",
      "branch": "Loei",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000027",
      "branch": "Nong Bua Lamphu",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000029",
      "branch": "Mukdahan",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000031",
      "branch": "Nakhon Phanom",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000032",
      "branch": "Kalasin",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000063",
      "branch": "Sakon Nakhon",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000067",
      "branch": "Sakon Nakhon/Phang Khon",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000028",
      "branch": "Khon Kaen/Chum Phae",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000034",
      "branch": "Khon Kaen",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000095",
      "branch": "Khon Kaen University",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000035",
      "branch": "Maha Sarakham",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000102",
      "branch": "Mahasarakham University",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000065",
      "branch": "Chaiyaphum",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000033",
      "branch": "Roi Et",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000036",
      "branch": "Ubon Ratchathani",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000077",
      "branch": "Ubon Ratchathani",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000037",
      "branch": "Amnat Charoen",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000089",
      "branch": "Yasothon",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000039",
      "branch": "Si Sa Ket",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000040",
      "branch": "Sisaket/Kantharalak",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000071",
      "branch": "Surin",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000043",
      "branch": "Buriram",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000044",
      "branch": "Buriram/Soi 5",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000072",
      "branch": "Nakhon Ratchasima",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000073",
      "branch": "Nakhon Ratchasima/IT Plaza",
      "region": "UNS - Lower Northeast"
  }
];

const dataRegion = [{
  id: "AVI - BKK and Metro",
  name: "AVI - BKK and Metro"
},
{
  id: "AVI - East",
  name: "AVI - East"
},
{
  id: "UNS - Central",
  name: "UNS - Central"
},
{
  id: "UNS - North",
  name: "UNS - North"
},
{
  id: "UNS - South",
  name: "UNS - South"
},
{
  id: "UNS - Upper Northeast",
  name: "UNS - Upper Northeast"
},
{
  id: "UNS - Lower Northeast",
  name: "UNS - Lower Northeast"
}]


// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [row, setRow] = React.useState([]);
  const [dateRegA, setDateRegA] = React.useState([]);
  const [dateRegB, setDateRegB] = React.useState([]);
  const [dateRegC, setDateRegC] = React.useState([]);
  const [dateRegD, setDateRegD] = React.useState([]);
  const [dateRegE, setDateRegE] = React.useState([]);
  const [dateRegF, setDateRegF] = React.useState([]);
  const [dateRegG, setDateRegG] = React.useState([]);
  const [totalRegistration, setTotalRegistration] = React.useState(0);
  const [totalA, setTotalA] = React.useState(0);
  const [totalB, setTotalB] = React.useState(0);
  const [totalC, setTotalC] = React.useState(0);
  const [totalD, setTotalD] = React.useState(0);
  const [totalE, setTotalE] = React.useState(0);
  const [totalF, setTotalF] = React.useState(0);
  const [totalG, setTotalG] = React.useState(0);
  const [totalIMEI, setTotalIMEI] = React.useState(0);
  const [totalEmptyPartNo, setTotalEmptyPartNo] = React.useState(0);
  const [totalPurchasedIMEI, setTotalPurchasedIMEI] = React.useState(0);

  const { user } = UserAuth()

  useEffect(() => {

          
    let _user = "";
    if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
      _user = JSON.parse(user.reloadUserInfo.customAttributes);
    }

    const arr = [];
    const arr2 = [];
    const arr3 = [];
    const arr4 = [];

    const dateArrayA = {}
    const dateArrayB = {}
    const dateArrayC = {}
    const dateArrayD = {}
    const dateArrayE = {}
    const dateArrayF = {}
    const dateArrayG = {}

    let _totalA = 0;
    let _totalB = 0;
    let _totalC = 0;
    let _totalD = 0;
    let _totalE = 0;
    let _totalF = 0;
    let _totalG = 0;

      get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach(doc=>{

            const currentindex = data.map(val=> val.id).indexOf(doc.val().outlet)

            if(currentindex !== -1)
            {
              arr.push({
                ...doc.val(),
                id: doc.key,
                region: data[currentindex].region
              });
            }
            
          })

          for (let i = 0; i < 11; i += 1) {

            const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
            const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`

            dateArrayA[i] = { date: _priorDate, total :0 }
            dateArrayB[i] = { date: _priorDate, total :0 }
            dateArrayC[i] = { date: _priorDate, total :0 }
            dateArrayD[i] = { date: _priorDate, total :0 }
            dateArrayE[i] = { date: _priorDate, total :0 }
            dateArrayF[i] = { date: _priorDate, total :0 }
            dateArrayG[i] = { date: _priorDate, total :0 }
          } 

          arr.forEach((val) => {
          
            if(val.region === dataRegion[0].id){
  
              _totalA += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayA[i].total += 1;
                }
                
              }
            }
            else if(val.region === dataRegion[1].id){
  
              _totalB += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayB[i].total += 1;
                }
                
              }
            }
            else if(val.region === dataRegion[2].id){
  
              _totalC += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayC[i].total += 1;
                }
                
              }
            }
            else if(val.region === dataRegion[3].id){
  
              _totalD += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayD[i].total += 1;
                }
                
              }
            }
            else if(val.region === dataRegion[4].id){
  
              _totalE += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayE[i].total += 1;
                }
                
              }
            }
            else if(val.region === dataRegion[5].id){
  
              _totalF += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayF[i].total += 1;
                }
                
              }
            }
            else if(val.region === dataRegion[6].id){
  
              _totalG += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayG[i].total += 1;
                }
                
              }
            }

          })

          setDateRegA(dateArrayA)
          setDateRegB(dateArrayB)
          setDateRegC(dateArrayC)
          setDateRegD(dateArrayD)
          setDateRegE(dateArrayE)
          setDateRegF(dateArrayF)
          setDateRegG(dateArrayG)

          setTotalA(_totalA)
          setTotalB(_totalB)
          setTotalC(_totalC)
          setTotalD(_totalD)
          setTotalE(_totalE)
          setTotalF(_totalF)
          setTotalG(_totalG)

          
          setTotalRegistration(snapshot.size)
          setLoading(false);

        } else {
          setRow(arr);
          setLoading(false);
        }
      }).catch((error) => {
        console.error(error);
      });

    

    /* const doFetch = async () => {
    // const dataRef = collection(db, "device-registration-1");
    // const q = query(dataRef, orderBy("created", 'asc'), limitToLast(10000));
    // const querySnapshot = await getDocs(collection(db, "device-registration-1"));
    
    const querySnapshot = await getDocs(collection(db, "device-registration-1"));
    const arr = [];
    const dateArrayRegSrilanka = {}
    const dateArrayRegBrunei = {}
    const dateArrayRegNepal = {}
    const dateArrayRegMongolia = {}
    let _totalSrilanka = 0;
    let _totalBrunei = 0;
    let _totalNepal = 0;
    let _totalMongolia = 0;

    // const querySnapshot = await getDocs(q);

    if(querySnapshot.empty)
    {
      if (isMounted) {
        
        setRow(arr);
        setLoading(false);
      }
    }
    else
    {

      querySnapshot.forEach((doc) => {
        if(doc.data().country === _user.country)
        {
          arr.push({
            ...doc.data(),
            id: doc.id,
          });
        }
        else if(_user.role === "super_admin"){
          arr.push({
            ...doc.data(),
            id: doc.id,
          });
        }
      });


      if (isMounted) {
        const _arr = getUnique(arr,'imeino');
        setRow(_arr);
        
        
        for (let i = 0; i < 11; i += 1) {

          const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
              
          const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`

          dateArrayRegSrilanka[i] = { date: _priorDate, total :0 }
          dateArrayRegBrunei[i] = { date: _priorDate, total :0 }
          dateArrayRegNepal[i] = { date: _priorDate, total :0 }
          dateArrayRegMongolia[i] = { date: _priorDate, total :0 }
        } 

        _arr.forEach((val) => {
          
          if(moment(val.purchase_date).isAfter('2023-10-02')){

            if(val.country.toLowerCase() === "srilanka"){

              
              _totalSrilanka += 1;
  
              const _RegDate = `${new Date(val.created.toMillis()).getMonth() + 1}/${new Date(val.created.toMillis()).getDate()}/${new Date(val.created.toMillis()).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegSrilanka[i].total += 1;
                }
                
              }
  
            }
            if(val.country.toLowerCase() === "brunei"){

              // Set the value of 'NYC'
              _totalBrunei += 1;
             
              const _RegDate = `${new Date(val.created.toMillis()).getMonth() + 1}/${new Date(val.created.toMillis()).getDate()}/${new Date(val.created.toMillis()).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
  
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegBrunei[i].total += 1;
                }
                
              }
  
            }
            if(val.country.toLowerCase() === "nepal"){

              
              _totalNepal += 1;
  
              const _RegDate = `${new Date(val.created.toMillis()).getMonth() + 1}/${new Date(val.created.toMillis()).getDate()}/${new Date(val.created.toMillis()).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegNepal[i].total += 1;
                }
                
              }
            }
            if(val.country.toLowerCase() === "mongolia"){

              _totalMongolia += 1;
  
              const _RegDate = `${new Date(val.created.toMillis()).getMonth() + 1}/${new Date(val.created.toMillis()).getDate()}/${new Date(val.created.toMillis()).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegMongolia[i].total += 1;
                }
                
              }
            }

          }
         
        })


        setDateRegSrilanka(dateArrayRegSrilanka)
        setDateRegBrunei(dateArrayRegBrunei)
        setDateRegNepal(dateArrayRegNepal)
        setDateRegMongolia(dateArrayRegMongolia)
        setTotalSrilanka(_totalSrilanka)
        setTotalBrunei(_totalBrunei)
        setTotalNepal(_totalNepal)
        setTotalMongolia(_totalMongolia)
        
        setLoading(false);
      } 
      
    }; 
    }
    

    doFetch() // start the async work
      .catch((err) => {
        if (!isMounted) return; // unmounted, ignore.
        // TODO: Handle errors in your component
        console.error("failed to fetch data", err);
      });

    return () => {
      isMounted = false;
    }; */
  }, [])

  const getUnique = (arr, index) => {

    const unique = arr
         .map(e => e[index])
  
         // store the keys of the unique objects
         .map((e, i, final) => final.indexOf(e) === i && i)
    
         // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);      
  
     return unique;
  }

  if(loading){
    return (
      
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>
        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
        flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
      <title> Dashboard | Advice </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>

        <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Registration" total={totalRegistration} color="warning" icon={'twemoji:mobile-phone'} />
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={dataRegion[0].name} total={totalA} icon={'twemoji:mobile-phone'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={dataRegion[1].name} total={totalB} icon={'twemoji:mobile-phone'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={dataRegion[2].name} total={totalC} icon={'twemoji:mobile-phone'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={dataRegion[3].name} total={totalD} icon={'twemoji:mobile-phone'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={dataRegion[4].name} total={totalE} icon={'twemoji:mobile-phone'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={dataRegion[5].name} total={totalF} icon={'twemoji:mobile-phone'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={dataRegion[6].name} total={totalG} icon={'twemoji:mobile-phone'} />
          </Grid>

          

          {dateRegA.length > 0 ? <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Registration Stats"
              chartLabels={[
                dateRegA[0].date,
                dateRegA[1].date,
                dateRegA[2].date,
                dateRegA[3].date,
                dateRegA[4].date,
                dateRegA[5].date,
                dateRegA[6].date,
                dateRegA[7].date,
                dateRegA[8].date,
                dateRegA[9].date,
                dateRegA[10].date,
              ]}
              chartData={[
                {
                  name: dataRegion[0].name,
                  type: 'area',
                  fill: 'gradient',
                  data: [dateRegA[0].total, 
                  dateRegA[1].total, 
                  dateRegA[2].total, 
                  dateRegA[3].total, 
                  dateRegA[4].total, 
                  dateRegA[5].total, 
                  dateRegA[6].total, 
                  dateRegA[7].total, 
                  dateRegA[8].total, 
                  dateRegA[9].total, 
                  dateRegA[10].total],
                },
                {
                  name: dataRegion[1].name,
                  type: 'area',
                  fill: 'gradient',
                  data: [dateRegB[0].total, 
                  dateRegB[1].total, 
                  dateRegB[2].total, 
                  dateRegB[3].total, 
                  dateRegB[4].total, 
                  dateRegB[5].total, 
                  dateRegB[6].total, 
                  dateRegB[7].total, 
                  dateRegB[8].total, 
                  dateRegB[9].total, 
                  dateRegB[10].total],
                },
                {
                  name: dataRegion[2].name,
                  type: 'area',
                  fill: 'gradient',
                  data: [dateRegC[0].total, 
                  dateRegC[1].total, 
                  dateRegC[2].total, 
                  dateRegC[3].total, 
                  dateRegC[4].total, 
                  dateRegC[5].total, 
                  dateRegC[6].total, 
                  dateRegC[7].total, 
                  dateRegC[8].total, 
                  dateRegC[9].total, 
                  dateRegC[10].total],
                },
                {
                  name: dataRegion[3].name,
                  type: 'area',
                  fill: 'gradient',
                  data: [dateRegD[0].total, 
                  dateRegD[1].total, 
                  dateRegD[2].total, 
                  dateRegD[3].total, 
                  dateRegD[4].total, 
                  dateRegD[5].total, 
                  dateRegD[6].total, 
                  dateRegD[7].total, 
                  dateRegD[8].total, 
                  dateRegD[9].total, 
                  dateRegD[10].total],
                }
                ,
                {
                  name: dataRegion[4].name,
                  type: 'area',
                  fill: 'gradient',
                  data: [dateRegE[0].total, 
                  dateRegE[1].total, 
                  dateRegE[2].total, 
                  dateRegE[3].total, 
                  dateRegE[4].total, 
                  dateRegE[5].total, 
                  dateRegE[6].total, 
                  dateRegE[7].total, 
                  dateRegE[8].total, 
                  dateRegE[9].total, 
                  dateRegE[10].total],
                }
                ,
                {
                  name: dataRegion[5].name,
                  type: 'area',
                  fill: 'gradient',
                  data: [dateRegF[0].total, 
                  dateRegF[1].total, 
                  dateRegF[2].total, 
                  dateRegF[3].total, 
                  dateRegF[4].total, 
                  dateRegF[5].total, 
                  dateRegF[6].total, 
                  dateRegF[7].total, 
                  dateRegF[8].total, 
                  dateRegF[9].total, 
                  dateRegF[10].total],
                }
                ,
                {
                  name: dataRegion[6].name,
                  type: 'area',
                  fill: 'gradient',
                  data: [dateRegG[0].total, 
                  dateRegG[1].total, 
                  dateRegG[2].total, 
                  dateRegG[3].total, 
                  dateRegG[4].total, 
                  dateRegG[5].total, 
                  dateRegG[6].total, 
                  dateRegG[7].total, 
                  dateRegG[8].total, 
                  dateRegG[9].total, 
                  dateRegG[10].total],
                }
              ]}
            />
            </Grid> : null}

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Registration"
              chartData={[
                { label: dataRegion[0].name, value: totalA },
                { label: dataRegion[1].name, value: totalB },
                { label: dataRegion[2].name, value: totalC },
                { label: dataRegion[3].name, value: totalD },
                { label: dataRegion[4].name, value: totalE },
                { label: dataRegion[5].name, value: totalF },
                { label: dataRegion[6].name, value: totalG },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
            </Grid>
          

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Top 10 Products Stats"
              chartData={[
                { label: 'IPHONE 14 PRO MAX DPUR 256GB A2894-ITP', value: 400 },
                { label: 'IPHONE 14 PRO MAX DPUR 128GB A2894-ITP', value: 430 },
                { label: 'IPHONE 14 PRO DEEP PUR 128GB A2890-ITP', value: 448 },
                { label: 'IPHONE 14 PRO DEEP PUR 256GB A2890-ITP', value: 470 },
                { label: 'IPHONE 14 PRO MAX SBLACK 256GB A2894-ITP', value: 540 },
                { label: 'IPHONE 14 PURPLE 128GB A2882-ITP', value: 580 },
                { label: 'IPHONE 14 PRO MAX SBLACK 128GB A2894-ITP', value: 690 },
                { label: 'IPHONE 14 BLUE 128GB A2882-ITP', value: 1100 },
                { label: 'IPHONE 14 PRO MAX GOLD 256GB A2894-ITP', value: 1200 },
                { label: 'IPHONE 14 PRO SPACE BLK 128GB A2890-ITP', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['64gb', '128gb', '256gb', '512gb', '1T', '2T']}
              chartData={[
                { name: 'Iphone Series 13', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Iphone Series 14', data: [20, 30, 40, 80, 20, 80] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
            </Grid> */}

          
        </Grid>
      </Container>
    </>
  );
}
