import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import * as React from 'react';
// @mui
import { Stack, Typography, Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Carousel } from '@angelplayer/react-carousel';

// ----------------------------------------------------------------------

const slides = [
    { image: '/assets/images/banners/banner-1.jpg', url: '#' },
    { image: '/assets/banner/banner1.png', url: '#' },
    { image: '/assets/banner/banner2.png', url: '#' },
    { image: '/assets/banner/banner3.png', url: '#' },
    { image: '/assets/banner/banner4.png', url: '#' },
    
];

const useStyles = makeStyles({
  select: {
      '&:before': {
          borderColor: 'white',
      },
      '&:after': {
          borderColor: 'white',
      },
      '&:not(.Mui-disabled):hover::before': {
          borderColor: 'white',
      },
  },
  icon: {
      fill: 'white',
  },
  root: {
      color: 'white',
  },
})

const data = [
  {
      "id": "51000011",
      "branch": "Pantip Plaza Ngamwongwan",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000014",
      "branch": "IT Mall Fortune Town",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000018",
      "branch": "Imperial Samrong",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000028",
      "branch": "Central Rama 2",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000034",
      "branch": "Robinson Ratchaphruek ",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000038",
      "branch": "Central Westville",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000012",
      "branch": "Central Westgate",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000013",
      "branch": "Seacon Bang Khae",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000019",
      "branch": "Seacon Srinakarin",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000035",
      "branch": "Central World",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000021",
      "branch": " The Forrest",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000033",
      "branch": "Central Rama 3",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000017",
      "branch": "Zeer Rangsit",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000022",
      "branch": "Fashion Island",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000029",
      "branch": "Mega Bangna",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000002",
      "branch": "Prachin Buri",
      "region": "AVI - East"
  },
  {
      "id": "51000003",
      "branch": "Chachoengsao",
      "region": "AVI - East"
  },
  {
      "id": "51000008",
      "branch": "Chonburi/Atara Mall",
      "region": "AVI - East"
  },
  {
      "id": "51000037",
      "branch": "Chonburi/Harbor Mall Laem Chabang",
      "region": "AVI - East"
  },
  {
      "id": "51000009",
      "branch": "Tukcom Chonburi",
      "region": "AVI - East"
  },
  {
      "id": "51000026",
      "branch": "Chonburi/Ban Suan Intersection",
      "region": "AVI - East"
  },
  {
      "id": "51000010",
      "branch": "Chonburi/Pattaya",
      "region": "AVI - East"
  },
  {
      "id": "51000036",
      "branch": "Tukcom Pattaya",
      "region": "AVI - East"
  },
  {
      "id": "51000001",
      "branch": "Chanthaburi",
      "region": "AVI - East"
  },
  {
      "id": "51000031",
      "branch": "Central Chanthaburi",
      "region": "AVI - East"
  },
  {
      "id": "51000004",
      "branch": "Sa Kaeo",
      "region": "AVI - East"
  },
  {
      "id": "51000023",
      "branch": "Central Rayong",
      "region": "AVI - East"
  },
  {
      "id": "51000025",
      "branch": " Rayong",
      "region": "AVI - East"
  },
  {
      "id": "51000006",
      "branch": "Trat",
      "region": "AVI - East"
  },
  {
      "id": "52000045",
      "branch": "Lopburi/Sa Kaeo Circle",
      "region": "UNS - Central"
  },
  {
      "id": "52000046",
      "branch": "Lopburi/Phra Narai",
      "region": "UNS - Central"
  },
  {
      "id": "52000047",
      "branch": "Sing Buri",
      "region": "UNS - Central"
  },
  {
      "id": "52000050",
      "branch": "Chai Nat",
      "region": "UNS - Central"
  },
  {
      "id": "52000078",
      "branch": "Ayutthaya",
      "region": "UNS - Central"
  },
  {
      "id": "52000082",
      "branch": "Saraburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000083",
      "branch": "Suphan Buri",
      "region": "UNS - Central"
  },
  {
      "id": "52000001",
      "branch": "Nakhon Pathom",
      "region": "UNS - Central"
  },
  {
      "id": "52000092",
      "branch": "Central Nakhon Pathom",
      "region": "UNS - Central"
  },
  {
      "id": "52000048",
      "branch": "Kanchanaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000049",
      "branch": "Phetchaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000080",
      "branch": "Prachuap Khiri Khan",
      "region": "UNS - Central"
  },
  {
      "id": "52000085",
      "branch": "Ratchaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000084",
      "branch": "Central Mahachai",
      "region": "UNS - Central"
  },
  {
      "id": "52000003",
      "branch": "Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000094",
      "branch": "Icon IT Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000097",
      "branch": "Central Festival Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000004",
      "branch": "Lamphun",
      "region": "UNS - North"
  },
  {
      "id": "52000006",
      "branch": "Chiang Rai",
      "region": "UNS - North"
  },
  {
      "id": "52000099",
      "branch": "Central Chiang Rai",
      "region": "UNS - North"
  },
  {
      "id": "52000007",
      "branch": "Chiang Rai/Mae Sai",
      "region": "UNS - North"
  },
  {
      "id": "52000008",
      "branch": "Lampang",
      "region": "UNS - North"
  },
  {
      "id": "52000009",
      "branch": "Phrea",
      "region": "UNS - North"
  },
  {
      "id": "52000010",
      "branch": "Nan",
      "region": "UNS - North"
  },
  {
      "id": "52000011",
      "branch": "Phayao",
      "region": "UNS - North"
  },
  {
      "id": "52000012",
      "branch": "Uttaradit",
      "region": "UNS - North"
  },
  {
      "id": "52000013",
      "branch": "Sukhothai",
      "region": "UNS - North"
  },
  {
      "id": "52000018",
      "branch": "Phichit",
      "region": "UNS - North"
  },
  {
      "id": "52000019",
      "branch": "Phichit/Taphan Hin",
      "region": "UNS - North"
  },
  {
      "id": "52000020",
      "branch": "Phitsanulok",
      "region": "UNS - North"
  },
  {
      "id": "52000061",
      "branch": "Phetchabun",
      "region": "UNS - North"
  },
  {
      "id": "52000015",
      "branch": "Kamphaeng Phet",
      "region": "UNS - North"
  },
  {
      "id": "52000016",
      "branch": "Nakhon Sawan",
      "region": "UNS - North"
  },
  {
      "id": "52000062",
      "branch": "Central Nakhon Sawan",
      "region": "UNS - North"
  },
  {
      "id": "52000017",
      "branch": "Uthai Thani",
      "region": "UNS - North"
  },
  {
      "id": "52000021",
      "branch": "Tak",
      "region": "UNS - North"
  },
  {
      "id": "52000052",
      "branch": "Krabi",
      "region": "UNS - South"
  },
  {
      "id": "52000058",
      "branch": "Nakhon Si Thammarat/Thung Song",
      "region": "UNS - South"
  },
  {
      "id": "52000059",
      "branch": "Nakhon Si Thammarat",
      "region": "UNS - South"
  },
  {
      "id": "52000088",
      "branch": "Central Nakhon Si Thammarat",
      "region": "UNS - South"
  },
  {
      "id": "52000090",
      "branch": "Trang",
      "region": "UNS - South"
  },
  {
      "id": "52000051",
      "branch": "Phatthalung",
      "region": "UNS - South"
  },
  {
      "id": "52000054",
      "branch": "Songkhla/Hat Yai",
      "region": "UNS - South"
  },
  {
      "id": "52000096",
      "branch": "Central Hat Yai",
      "region": "UNS - South"
  },
  {
      "id": "52000055",
      "branch": "Songkhla",
      "region": "UNS - South"
  },
  {
      "id": "52000093",
      "branch": "Satun",
      "region": "UNS - South"
  },
  {
      "id": "52000053",
      "branch": "Ranong",
      "region": "UNS - South"
  },
  {
      "id": "52000057",
      "branch": "Phuket",
      "region": "UNS - South"
  },
  {
      "id": "52000098",
      "branch": "Central Phuket Festival",
      "region": "UNS - South"
  },
  {
      "id": "52000060",
      "branch": "ชุมพร ",
      "region": "UNS - South"
  },
  {
      "id": "52000086",
      "branch": "Surat Thani",
      "region": "UNS - South"
  },
  {
      "id": "52000087",
      "branch": "Central Suratthani",
      "region": "UNS - South"
  },
  {
      "id": "52000100",
      "branch": "Pattani",
      "region": "UNS - South"
  },
  {
      "id": "52000101",
      "branch": "Narathiwat",
      "region": "UNS - South"
  },
  {
      "id": "52000024",
      "branch": "Udon Thani",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000069",
      "branch": "Tukcom Udon Thani",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000064",
      "branch": "Nong Khai",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000066",
      "branch": "Loei",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000027",
      "branch": "Nong Bua Lamphu",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000029",
      "branch": "Mukdahan",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000031",
      "branch": "Nakhon Phanom",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000032",
      "branch": "Kalasin",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000063",
      "branch": "Sakon Nakhon",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000067",
      "branch": "Sakon Nakhon/Phang Khon",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000028",
      "branch": "Khon Kaen/Chum Phae",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000034",
      "branch": "Khon Kaen",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000095",
      "branch": "Khon Kaen University",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000035",
      "branch": "Maha Sarakham",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000102",
      "branch": "Mahasarakham University",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000065",
      "branch": "Chaiyaphum",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000033",
      "branch": "Roi Et",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000036",
      "branch": "Ubon Ratchathani",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000077",
      "branch": "Ubon Ratchathani",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000037",
      "branch": "Amnat Charoen",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000089",
      "branch": "Yasothon",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000039",
      "branch": "Si Sa Ket",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000040",
      "branch": "Sisaket/Kantharalak",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000071",
      "branch": "Surin",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000043",
      "branch": "Buriram",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000044",
      "branch": "Buriram/Soi 5",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000072",
      "branch": "Nakhon Ratchasima",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000073",
      "branch": "Nakhon Ratchasima/IT Plaza",
      "region": "UNS - Lower Northeast"
  }
];

const dataRegion = [{
    id: "AVI - BKK and Metro",
    name: "AVI - BKK and Metro"
  },
  {
    id: "AVI - East",
    name: "AVI - East"
  },
  {
    id: "UNS - Central",
    name: "UNS - Central"
  },
  {
    id: "UNS - North",
    name: "UNS - North"
  },
  {
    id: "UNS - South",
    name: "UNS - South"
  },
  {
    id: "UNS - Upper Northeast",
    name: "UNS - Upper Northeast"
  },
  {
    id: "UNS - Lower Northeast",
    name: "UNS - Lower Northeast"
  }]

export default function HomePage() {

  const [outlet, setOutlet] = React.useState('51000001');
  const [region, setRegion] = React.useState('AVI - East');
  const classes = useStyles()

  const handleChange = (event) => {
    setOutlet(event.target.value);
  };

  const handleRegionChange = (event) => {
    const _data = data.sort((a, b) => a.id- b.id).filter(val => val.region === event.target.value);
    setOutlet(_data[0].id)
    setRegion(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Home |Advice </title>
      </Helmet>

      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column", paddingBottom: 80  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column", alignItems: "center" }}>
          <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#cecece' }}>
          REGISTER NOW FOR Your Protection Care Plan
            </Typography>

          <Typography align="center" style={{ fontWeight: 700, fontSize: 36, color: '#cecece', marginTop: 15 }}>
          Mobile
          </Typography>
          
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "black" }}>
          <Grid style={{ backgroundColor: "black", padding: 15, paddingBottom: 0, width: 900 }}>
          <Carousel slides={slides} speed='5000' type='contain' />
          </Grid>
        </Grid>
        

        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60,paddingBottom: 80,
          flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
           <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#cecece' }}>
           REGISTER NOW FOR Your Protection Care Plan
            </Typography>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "white", flexDirection: "column" }}>
          <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#151515', marginTop: 20 }}>
          Protect your biggest purchases with our protection care plan
          </Typography>
          <Grid style={{ display: "flex", justifyContent: "center", backgroundColor: "white", padding: 20 }}>
            <img src="/assets/images/banners/banner2-1.jpg" alt='banner2' width={860} />
          </Grid>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60, paddingBottom: 80,
          flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
          <Typography style={{ fontWeight: 700, fontSize: 36, color: '#cecece' }}>
          Search Your Protection Care Plan
          </Typography>
          <Typography style={{ fontWeight: 100, fontSize: 14, color: '#cecece' }}>
          Now you can search your your protection care plan at anytime. Search your care plan now!!
          </Typography>
          <Button variant="contained" style={{ marginTop: 20, backgroundColor: "red" }} href={"/search-protection-care-plan"}   disableElevation>
          Search Protection Care Plan
          </Button>
        </Grid>


        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60,
          flexDirection: "column",  alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 36, color: '#cecece' }}>
          REGISTER NOW FOR Your Protection Care Plan
          </Typography>
          <Typography style={{ fontWeight: 100, fontSize: 14, color: '#cecece' }}>
          Protection Care Plan Registration
Register your device according to which outlet you purchased your device
          </Typography>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", paddingBottom: 20 }}>
          <Grid style={{ width: 300 }}>
            <FormControl fullWidth >
                
                <InputLabel id="demo-simple-select-label" sx={{ color: "white"}}>Region</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={region}
                  label="Region"
                  sx={{

                    height: '3.5rem',
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white'
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'white'
                    }
                    
                    
                }}
                  onChange={handleRegionChange}
                >
                {dataRegion.sort((a, b) => a.id- b.id).map(val=> {
                  return <MenuItem value={val.id}>{`${val.id}`}</MenuItem>
                })}
                </Select>

              </FormControl>
              <FormControl fullWidth  style={{ marginTop: 20 }}>
                
                <InputLabel id="demo-simple-select-label" sx={{ color: "white"}}>Outlet</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={outlet}
                  label="Outlet"
                  sx={{

                    height: '3.5rem',
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white'
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'white'
                    }
                    
                    
                }}
                  onChange={handleChange}
                >
                {data.sort((a, b) => a.id- b.id).filter(val => val.region === region).map(val=> {

                  return <MenuItem value={val.id}>{`${val.id} - ${val.branch}`}</MenuItem>
                  
                })}
                </Select>

                <Button variant="contained" style={{ marginTop: 20, backgroundColor: "red" }} href={`register-protection-care-plan/${outlet}`}   disableElevation>
                  Register Now
                </Button>
              </FormControl>
            </Grid>
        </Grid>

        
      </Grid>
    </>
  );
}
