import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import * as FileSaver from 'file-saver';
import * as React from 'react';
import XLSX from 'sheetjs-style';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import moment from 'moment'
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Tooltip,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Grid
} from '@mui/material';

import Link from '@mui/material/Link';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import dayjs from 'dayjs';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import {
  collection, getDocs, getFirestore, addDoc, updateDoc, doc,
  serverTimestamp, onSnapshot, query, orderBy, limitToLast, Timestamp, writeBatch
} from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/device';

import { UserAuth } from '../context/AuthContext'

const firebaseConfig = {
  apiKey: "AIzaSyBhHLgOLj4StzNAGDJ4el1pOPePWzt-w3g",
  authDomain: "advice-cf528.firebaseapp.com",
  databaseURL: "https://advice-cf528-default-rtdb.firebaseio.com",
  projectId: "advice-cf528",
  storageBucket: "advice-cf528.appspot.com",
  messagingSenderId: "835498848483",
  appId: "1:835498848483:web:1f1d60736063ffb5a215d6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const rtdb = getDatabase(app);

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Registration ID', alignRight: false },
  { id: 'created', label: 'Registration Date/Time', alignRight: false },
  { id: 'purchase_date', label: 'Purchase Date', alignRight: false },
  { id: 'full_name', label: 'Full Name', alignRight: false },
  { id: 'imeino', label: 'Imei', alignRight: false },
  { id: 'outlet', label: 'Outlet', alignRight: false },
  { id: 'plan', label: 'Plan', alignRight: false },
  { id: '' },
];

const plandata = [{
  id: "ip_basic_plus_1",
  name: "Instaprotection BASIC + 1",
  description: "Samsung/Huawei/Honor/Oppo/Xiaomi/ Poco/Redmi/Realme/Vivo",
  term: "ยกเว้น Samsung Fold / Samsung Flip /Samsung S24+/Samsung S24Ultra / Oppo Find N Flip /Xiaomi 12Pro/ Xiaomi 13 Pro/ Vivo NEX/ Vivo X Pro Series)"
},
{
  id: "ip_basic_plus_2",
  name: "Instaprotection BASIC + 2",
  description: "สำหรับ Apple iPhone X/ iPhone XR/ iPhone Xs/ iPhone XS Max",
  term: ""
},
{
  id: "ip_basic_plus_3",
  name: "Instaprotection BASIC + 3",
  description: "สำหรับ iPhone 11, 12 , 13, 14 ทุกรุ่น และ Samsung S23 Series (จอหน้า+กระจกหลัง)",
  term: "สำหรับ iPhone 11, 12,13,14 ราคาประกันนี้เฉพาะกระจกหน้าเท่านั้น"
},
{
  id: "ip_basic_plus_4",
  name: "Instaprotection BASIC + 4",
  description: "Samsung Fold / Samsung Flip /Samsung S24+/Samsung S24Ultra / Oppo Find N Flip /Xiaomi 12 Pro/ Xiaomi 13 Pro/ Vivo NEXI Vivo X Pro Series และ iPhone 15 ทุกรุ่น",
  term: ""
}

];

const dataBranch = [
  {
      "id": "51000011",
      "branch": "Pantip Plaza Ngamwongwan",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000014",
      "branch": "IT Mall Fortune Town",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000018",
      "branch": "Imperial Samrong",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000028",
      "branch": "Central Rama 2",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000034",
      "branch": "Robinson Ratchaphruek ",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000038",
      "branch": "Central Westville",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000012",
      "branch": "Central Westgate",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000013",
      "branch": "Seacon Bang Khae",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000019",
      "branch": "Seacon Srinakarin",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000035",
      "branch": "Central World",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000021",
      "branch": " The Forrest",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000033",
      "branch": "Central Rama 3",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000017",
      "branch": "Zeer Rangsit",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000022",
      "branch": "Fashion Island",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000029",
      "branch": "Mega Bangna",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000002",
      "branch": "Prachin Buri",
      "region": "AVI - East"
  },
  {
      "id": "51000003",
      "branch": "Chachoengsao",
      "region": "AVI - East"
  },
  {
      "id": "51000008",
      "branch": "Chonburi/Atara Mall",
      "region": "AVI - East"
  },
  {
      "id": "51000037",
      "branch": "Chonburi/Harbor Mall Laem Chabang",
      "region": "AVI - East"
  },
  {
      "id": "51000009",
      "branch": "Tukcom Chonburi",
      "region": "AVI - East"
  },
  {
      "id": "51000026",
      "branch": "Chonburi/Ban Suan Intersection",
      "region": "AVI - East"
  },
  {
      "id": "51000010",
      "branch": "Chonburi/Pattaya",
      "region": "AVI - East"
  },
  {
      "id": "51000036",
      "branch": "Tukcom Pattaya",
      "region": "AVI - East"
  },
  {
      "id": "51000001",
      "branch": "Chanthaburi",
      "region": "AVI - East"
  },
  {
      "id": "51000031",
      "branch": "Central Chanthaburi",
      "region": "AVI - East"
  },
  {
      "id": "51000004",
      "branch": "Sa Kaeo",
      "region": "AVI - East"
  },
  {
      "id": "51000023",
      "branch": "Central Rayong",
      "region": "AVI - East"
  },
  {
      "id": "51000025",
      "branch": " Rayong",
      "region": "AVI - East"
  },
  {
      "id": "51000006",
      "branch": "Trat",
      "region": "AVI - East"
  },
  {
      "id": "52000045",
      "branch": "Lopburi/Sa Kaeo Circle",
      "region": "UNS - Central"
  },
  {
      "id": "52000046",
      "branch": "Lopburi/Phra Narai",
      "region": "UNS - Central"
  },
  {
      "id": "52000047",
      "branch": "Sing Buri",
      "region": "UNS - Central"
  },
  {
      "id": "52000050",
      "branch": "Chai Nat",
      "region": "UNS - Central"
  },
  {
      "id": "52000078",
      "branch": "Ayutthaya",
      "region": "UNS - Central"
  },
  {
      "id": "52000082",
      "branch": "Saraburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000083",
      "branch": "Suphan Buri",
      "region": "UNS - Central"
  },
  {
      "id": "52000001",
      "branch": "Nakhon Pathom",
      "region": "UNS - Central"
  },
  {
      "id": "52000092",
      "branch": "Central Nakhon Pathom",
      "region": "UNS - Central"
  },
  {
      "id": "52000048",
      "branch": "Kanchanaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000049",
      "branch": "Phetchaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000080",
      "branch": "Prachuap Khiri Khan",
      "region": "UNS - Central"
  },
  {
      "id": "52000085",
      "branch": "Ratchaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000084",
      "branch": "Central Mahachai",
      "region": "UNS - Central"
  },
  {
      "id": "52000003",
      "branch": "Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000094",
      "branch": "Icon IT Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000097",
      "branch": "Central Festival Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000004",
      "branch": "Lamphun",
      "region": "UNS - North"
  },
  {
      "id": "52000006",
      "branch": "Chiang Rai",
      "region": "UNS - North"
  },
  {
      "id": "52000099",
      "branch": "Central Chiang Rai",
      "region": "UNS - North"
  },
  {
      "id": "52000007",
      "branch": "Chiang Rai/Mae Sai",
      "region": "UNS - North"
  },
  {
      "id": "52000008",
      "branch": "Lampang",
      "region": "UNS - North"
  },
  {
      "id": "52000009",
      "branch": "Phrea",
      "region": "UNS - North"
  },
  {
      "id": "52000010",
      "branch": "Nan",
      "region": "UNS - North"
  },
  {
      "id": "52000011",
      "branch": "Phayao",
      "region": "UNS - North"
  },
  {
      "id": "52000012",
      "branch": "Uttaradit",
      "region": "UNS - North"
  },
  {
      "id": "52000013",
      "branch": "Sukhothai",
      "region": "UNS - North"
  },
  {
      "id": "52000018",
      "branch": "Phichit",
      "region": "UNS - North"
  },
  {
      "id": "52000019",
      "branch": "Phichit/Taphan Hin",
      "region": "UNS - North"
  },
  {
      "id": "52000020",
      "branch": "Phitsanulok",
      "region": "UNS - North"
  },
  {
      "id": "52000061",
      "branch": "Phetchabun",
      "region": "UNS - North"
  },
  {
      "id": "52000015",
      "branch": "Kamphaeng Phet",
      "region": "UNS - North"
  },
  {
      "id": "52000016",
      "branch": "Nakhon Sawan",
      "region": "UNS - North"
  },
  {
      "id": "52000062",
      "branch": "Central Nakhon Sawan",
      "region": "UNS - North"
  },
  {
      "id": "52000017",
      "branch": "Uthai Thani",
      "region": "UNS - North"
  },
  {
      "id": "52000021",
      "branch": "Tak",
      "region": "UNS - North"
  },
  {
      "id": "52000052",
      "branch": "Krabi",
      "region": "UNS - South"
  },
  {
      "id": "52000058",
      "branch": "Nakhon Si Thammarat/Thung Song",
      "region": "UNS - South"
  },
  {
      "id": "52000059",
      "branch": "Nakhon Si Thammarat",
      "region": "UNS - South"
  },
  {
      "id": "52000088",
      "branch": "Central Nakhon Si Thammarat",
      "region": "UNS - South"
  },
  {
      "id": "52000090",
      "branch": "Trang",
      "region": "UNS - South"
  },
  {
      "id": "52000051",
      "branch": "Phatthalung",
      "region": "UNS - South"
  },
  {
      "id": "52000054",
      "branch": "Songkhla/Hat Yai",
      "region": "UNS - South"
  },
  {
      "id": "52000096",
      "branch": "Central Hat Yai",
      "region": "UNS - South"
  },
  {
      "id": "52000055",
      "branch": "Songkhla",
      "region": "UNS - South"
  },
  {
      "id": "52000093",
      "branch": "Satun",
      "region": "UNS - South"
  },
  {
      "id": "52000053",
      "branch": "Ranong",
      "region": "UNS - South"
  },
  {
      "id": "52000057",
      "branch": "Phuket",
      "region": "UNS - South"
  },
  {
      "id": "52000098",
      "branch": "Central Phuket Festival",
      "region": "UNS - South"
  },
  {
      "id": "52000060",
      "branch": "ชุมพร ",
      "region": "UNS - South"
  },
  {
      "id": "52000086",
      "branch": "Surat Thani",
      "region": "UNS - South"
  },
  {
      "id": "52000087",
      "branch": "Central Suratthani",
      "region": "UNS - South"
  },
  {
      "id": "52000100",
      "branch": "Pattani",
      "region": "UNS - South"
  },
  {
      "id": "52000101",
      "branch": "Narathiwat",
      "region": "UNS - South"
  },
  {
      "id": "52000024",
      "branch": "Udon Thani",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000069",
      "branch": "Tukcom Udon Thani",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000064",
      "branch": "Nong Khai",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000066",
      "branch": "Loei",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000027",
      "branch": "Nong Bua Lamphu",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000029",
      "branch": "Mukdahan",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000031",
      "branch": "Nakhon Phanom",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000032",
      "branch": "Kalasin",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000063",
      "branch": "Sakon Nakhon",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000067",
      "branch": "Sakon Nakhon/Phang Khon",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000028",
      "branch": "Khon Kaen/Chum Phae",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000034",
      "branch": "Khon Kaen",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000095",
      "branch": "Khon Kaen University",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000035",
      "branch": "Maha Sarakham",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000102",
      "branch": "Mahasarakham University",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000065",
      "branch": "Chaiyaphum",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000033",
      "branch": "Roi Et",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000036",
      "branch": "Ubon Ratchathani",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000077",
      "branch": "Ubon Ratchathani",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000037",
      "branch": "Amnat Charoen",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000089",
      "branch": "Yasothon",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000039",
      "branch": "Si Sa Ket",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000040",
      "branch": "Sisaket/Kantharalak",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000071",
      "branch": "Surin",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000043",
      "branch": "Buriram",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000044",
      "branch": "Buriram/Soi 5",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000072",
      "branch": "Nakhon Ratchasima",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000073",
      "branch": "Nakhon Ratchasima/IT Plaza",
      "region": "UNS - Lower Northeast"
  }
];



// ----------------------------------------------------------------------

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy2) {
  if (b[orderBy2] < a[orderBy2]) {
    return -1;
  }
  if (b[orderBy2] > a[orderBy2]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy2) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy2)
    : (a, b) => -descendingComparator(a, b, orderBy2);
}

function applySortFilter(array, comparator, query, select) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    if (select === "imeino") {
      return filter(array, (_user) => _user.imeino.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if (select === "full_name") {
      return filter(array, (_user) => _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if (select === "id") {
      return filter(array, (_user) => _user.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if (select === "email") {
      return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if (select === "serialno") {
      return filter(array, (_user) => _user.serialno.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = '.xlsx';

export default function ProductPage() {

  const { user } = UserAuth()

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy2, setOrderBy2] = useState('created');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [row, setRow] = useState([]);

  const [fileUrl, setFileUrl] = useState(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState('');
  const [userRole, setUserRole] = useState('super_admin');

  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const [select, setSelect] = useState('id');

  const handleChangeSelect = (event) => {
    setSelect(event.target.value);
  };


  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [dialogOpen3, setDialogOpen3] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogClose2 = () => {
    setDialogOpen2(false);
  };

  const handleDialogClose3 = () => {
    setDialogOpen3(false);
  };


  const handleDialogSubmit = () => {

    /* if(selectedItem.status !== status)
    {
      updateDoc (doc(db, "device-registration-1", selectedItem.id), {
        status: status || null,
      })
    }
    
    setDialogOpen(false); */
  };

  useEffect(() => {


    let _user = "";
    if (user && user.reloadUserInfo && user.reloadUserInfo.customAttributes) {
      _user = JSON.parse(user.reloadUserInfo.customAttributes);
    }
    setUserRole(_user.role)

    const arr = [];
    get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
      if (snapshot.exists()) {

        snapshot.forEach((doc) => {

          arr.push({
            ...doc.val(),
            id: doc.key,
          });



          /* if(doc.val().country === "mongolia"){
            console.log(moment(new Date(doc.val().purchase_date)).unix())
            update(dbref(rtdb, `device-registration/${doc.val().imeino}`), {
              created: moment(new Date(doc.val().purchase_date)).unix() })
          } */

          /* if(doc.val().purchase_date.split("-")[2] === "2024" || doc.val().purchase_date.split("-")[2] === "2023")
          {
            console.log(doc.val().imeino)
            console.log(doc.val().purchase_date)
            update(dbref(rtdb, `device-registration/${doc.val().imeino}`), {
              purchase_date: `${doc.val().purchase_date.split("-")[2]}-${doc.val().purchase_date.split("-")[1]}-${doc.val().purchase_date.split("-")[0]}` })
          } */

          /* if(moment(`${new Date(doc.val().created*1000).getFullYear()}-${new Date(doc.val().created*1000).getMonth()+1}-${new Date(doc.val().created*1000).getDate()}`).isAfter('2024-01-24'))
          {
            console.log(moment(`${new Date(doc.val().created*1000).getFullYear()}-${new Date(doc.val().created*1000).getDate()}-${new Date(doc.val().created*1000).getMonth()+1}`).unix())
            update(dbref(rtdb, `device-registration/${doc.val().imeino}`), {
              created: moment(`${new Date(doc.val().created*1000).getFullYear()}-${new Date(doc.val().created*1000).getDate()}-${new Date(doc.val().created*1000).getMonth()+1}`).unix(), 
              purchase_date: moment(`${new Date(doc.val().created*1000).getFullYear()}-${new Date(doc.val().created*1000).getDate()}-${new Date(doc.val().created*1000).getMonth()+1}`).format("YYYY-MM-DD")})
          } */

        })


        setRow(arr);
        setLoading(false);

      } else {
        console.log("No data available");
        setLoading(false);
      }
    }).catch((error) => {
      console.error(error);
    });

    /* let isMounted = true;

    const doFetch = async () => {
      // const dataRef = collection(db, "device-registration-1");
      // const q = query(dataRef, orderBy("created", 'asc'), limitToLast(8000));
      // const querySnapshot = await getDocs(q);
      const querySnapshot = await getDocs(collection(db, "device-registration-1"));
      const arr = [];

      querySnapshot.forEach((val) => {

        if (!(val.data().created instanceof Timestamp)) {
          // console.log(val.data().created)
        }
       
        if(moment(val.data().purchase_date).isAfter('2024-01-20') && moment(val.data().purchase_date).isBefore('2024-01-25')){

          if(val.data().country === _user.country || !_user.country)
          {
            arr.push({
              ...val.data(),
              id: val.id,
            });
          }

        }
        
      });

      arr.forEach((val) => {
        set(dbref(rtdb, `device-registration/${val.imeino}`), {
          id: val.imeino,
          city: val.city,
          country: val.country,
          created: val.created.seconds,
          email: val.email,
          file_contentType: val.file_contentType,
          file_fullPath: val.file_fullPath,
          full_name: val.full_name,
          home_address: val.home_address,
          imeino: val.imeino,
          nic: val.nic,
          passport: val.passport,
          purchase_date: val.purchase_date,
          serialno: val.serialno,
          status: val.status
        });
      })

      if (isMounted) {
        setRow(getUnique(arr,'imeino'));
        setLoading(false);
      } 
    };

    doFetch() // start the async work
      .catch((err) => {
        if (!isMounted) return; // unmounted, ignore.
        // TODO: Handle errors in your component
        console.error("failed to fetch data", err);
      });

    return () => {
      isMounted = false;
    }; */

    /* const unsubscribe = onSnapshot(collection(db, "device-registration-1"), (querySnapshot) => {
      const arr = [];
      querySnapshot.forEach((doc) => {

        if(doc.data().country === _user.country)
          {
            arr.push({
              ...doc.data(),
              id: doc.id,
            });
          }
          else if(_user.role === "super_admin"){
            arr.push({
              ...doc.data(),
              id: doc.id,
            });
          }

      });

      setRow(arr)
      setLoading(false)
    });

    return () => {
      unsubscribe()
    }; */

  }, [])

  const getUnique = (arr, index) => {

    const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const handleSubmitDownload = async () => {

    console.log("download")
    setDialogLoading(true);

    const tempImei = [];
    get(child(dbref(rtdb), `imei`)).then((snapshot) => {
      if (snapshot.exists()) {

        snapshot.forEach((doc) => {
          tempImei.push({
            ...doc.val(),
            id: doc.key,
          });
        })
        console.log("imei")
        readProduct(tempImei)
      }
      else
      {
        readProduct(tempImei)
      }

    })

  }

  const readProduct = (tempImei) => {
    const tempProduct = [];
        get(child(dbref(rtdb), `product`)).then((snapshot1) => {
          if (snapshot1.exists()) {

            snapshot1.forEach((doc1) => {

              tempProduct.push({
                ...doc1.val(),
                id: doc1.key,
              });
            })

            console.log("product")
            
            downloadExcelFile(tempImei, tempProduct);
          }
          else
          {
            downloadExcelFile(tempImei, tempProduct);
          }
        })
  }

  const downloadExcelFile = (tempImei, tempProduct) => {
    tempImei.forEach(val => {

      const currentIndex = tempProduct.map(value => value.partno.replace(/\s/g, '').toUpperCase()).indexOf(val.partno.replace(/\s/g, '').toUpperCase());


      if (currentIndex !== -1) {
        val.capacity = tempProduct[currentIndex].capacity;
        val.color = tempProduct[currentIndex].color;
        val.product_name = tempProduct[currentIndex].name;
      }
    })

    const _newRow = []
    row.forEach(val => {

      console.log(val)
      if (moment(val.purchase_date).isSameOrAfter(startDate.format('YYYY-MM-DD'))
        && moment(val.purchase_date).isSameOrBefore(endDate.format('YYYY-MM-DD'))) {
        const currentIndex = tempImei.map(value => value.imeino.toString().replace(/\s/g, '')).indexOf(val.imeino.toString().replace(/\s/g, ''));

        const _item = {};
        _item["Registration ID"] = val.id;
        _item["Registration Date/Time"] = `${new Date(val.created * 1000).getFullYear()}-${new Date(val.created * 1000).getMonth() + 1}-${new Date(val.created * 1000).getDate()} (${new Date(val.created * 1000).getHours()}: ${new Date(val.created * 1000).getMinutes()})`;
        _item["Invoice Date"] = val.purchase_date;
        _item.Owner = val.full_name;
        _item["Email Address"] = val.email;

        _item.IMEI = val.imeino;
        _item["Serial No."] = val.serialno;
        _item.Warranty = "Screen Replacement Warranty,Extended Warranty";
        

        const currentIndex2 = dataBranch.map(val=> val.id).indexOf(val.outlet)
        const currentIndex3 = plandata.map(val=> val.id).indexOf(val.plan)

        _item.Plan = plandata[currentIndex3].name;
        _item.Mobile = val.mobile;
        _item.Salesman = val.sales_name;
        _item.Branch = dataBranch[currentIndex2].branch;
        _item.Region = dataBranch[currentIndex2].region;

        _item.Model = val.model_name;
        _item.Capacity = val.capacity;
        _item.Color = val.device_color;
        _item.Brand = val.brand;

        if (currentIndex !== -1) {
          _item["Product Name"] = tempImei[currentIndex].product_name;
          _item.Capacity = tempImei[currentIndex].capacity;
          _item.Color = tempImei[currentIndex].color;
          _item["Serial No."] = tempImei[currentIndex].serialno;
        }

        _newRow.push(_item)
      }

    })


    const ws = XLSX.utils.json_to_sheet(_newRow);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `device_registrations_data${fileExtension}`);

    setDialogOpen2(false);
    setDialogLoading(false);
  }

  const handleSubmitDownloadCreatedDate = async () => {

    setDialogLoading(true);

    const tempImei = [];
    get(child(dbref(rtdb), `imei`)).then((snapshot) => {
      if (snapshot.exists()) {

        snapshot.forEach((doc) => {
          tempImei.push({
            ...doc.val(),
            id: doc.key,
          });
        })

        readProduct2(tempImei)
      }
      else
      {
        readProduct2(tempImei)
      }

    })

  }

  const readProduct2 = (tempImei) => {
    const tempProduct = [];
        get(child(dbref(rtdb), `product`)).then((snapshot1) => {
          if (snapshot1.exists()) {

            snapshot1.forEach((doc1) => {

              tempProduct.push({
                ...doc1.val(),
                id: doc1.key,
              });
            })

            downloadExcelFile2(tempImei, tempProduct)

          }
          else
          {
            downloadExcelFile2(tempImei, tempProduct)
          }
        })
  }

  const downloadExcelFile2 = (tempImei, tempProduct) => {

    tempImei.forEach(val => {

      const currentIndex = tempProduct.map(value => value.partno.replace(/\s/g, '').toUpperCase()).indexOf(val.partno.replace(/\s/g, '').toUpperCase());


      if (currentIndex !== -1) {
        val.capacity = tempProduct[currentIndex].capacity;
        val.color = tempProduct[currentIndex].color;
        val.product_name = tempProduct[currentIndex].name;
      }
    })

    const _newRow = []


    row.forEach(val => {

      if (moment.unix(val.created).isSameOrAfter(startDate.format('YYYY-MM-DD'))
        && moment.unix(val.created).isSameOrBefore(endDate.format('YYYY-MM-DD'))) {
        const currentIndex = tempImei.map(value => value.imeino.toString().replace(/\s/g, '')).indexOf(val.imeino.toString().replace(/\s/g, ''));

        
        const _item = {};
        _item["Registration ID"] = val.id;
        _item["Registration Date/Time"] = `${new Date(val.created * 1000).getFullYear()}-${new Date(val.created * 1000).getMonth() + 1}-${new Date(val.created * 1000).getDate()} (${new Date(val.created * 1000).getHours()}: ${new Date(val.created * 1000).getMinutes()})`;
        _item["Invoice Date"] = val.purchase_date;
        _item.Owner = val.full_name;
        _item["Email Address"] = val.email;

        _item.IMEI = val.imeino;
        _item["Serial No."] = val.serialno;
        _item.Address = val.home_address;
        _item.Warranty = "Screen Replacement Warranty,Extended Warranty";

        const currentIndex2 = dataBranch.map(val=> val.id).indexOf(val.outlet)
        const currentIndex3 = plandata.map(val=> val.id).indexOf(val.plan)

        _item.Plan = plandata[currentIndex3].name;
        _item.Mobile = val.mobile;
        _item.Salesman = val.sales_name;
        _item.Branch = dataBranch[currentIndex2].branch;
        _item.Region = dataBranch[currentIndex2].region;

        _item.Model = val.model_name;
        _item.Capacity = val.capacity;
        _item.Color = val.device_color;
        _item.Brand = val.brand;

        if (currentIndex !== -1) {
          _item["Product Name"] = tempImei[currentIndex].product_name;
          _item.Capacity = tempImei[currentIndex].capacity;
          _item.Color = tempImei[currentIndex].color;
          _item["Serial No."] = tempImei[currentIndex].serialno;
        }

        _newRow.push(_item)
      }

    })


    const ws = XLSX.utils.json_to_sheet(_newRow);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `device_registrations_data_created_date${fileExtension}`);

    setDialogOpen3(false);
    setDialogLoading(false);
  }

  const downloadExcel = async () => {
    setDialogOpen2(true);
  }

  const downloadExcelCreatedDate = async () => {
    setDialogOpen3(true);
  }

  const handleOnDetails = () => {
    setOpenDetails(true);
    setOpen(false)
  }

  const handleOnEdit = () => {
    setDialogOpen(true);
    setOpen(false)
  }

  const handleOpenMenu = (event, row) => {
    setSelectedItem(row)
    setOpen(event.currentTarget);
    setStatus(row.status)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy2 === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy2(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = row.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - row.length) : 0;

  const filteredUsers = applySortFilter(row, getComparator(order, orderBy2), filterName, select);

  const isNotFound = !filteredUsers.length && !!filterName;

  if (loading) {
    return (

      <Grid style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>


        <Grid style={{
          width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
          flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  const renderCountry = (country) => {
    let _country = "";
    if (country === "srilanka") {
      _country = "Sri Lanka";
    }
    else if (country === "brunei") {
      _country = "Brunei";
    }
    else if (country === "nepal") {
      _country = "Nepal";
    }
    else if (country === "mongolia") {
      _country = "Mongolia";
    }
    return _country;
  }

  const renderStatus = (status) => {
    let _status = "";
    if (status === "pending_verification") {
      _status = "Pending Verification";
    }
    else if (status === "verified") {
      _status = "Verified";
    }

    return _status;
  }

  const renderFilePath = (path) => {

    const printUrl = async (_path) => {
      const storage = getStorage(app);
      const _url = await getDownloadURL(ref(storage, _path))
        .then((url) => {
          return url;
        })
        .catch((error) => {
          // Handle any errors
        });
      setFileUrl(_url)
    };

    printUrl(path);

    return fileUrl;
  }

  const getOutletName = (outlet) => {
    let name = "";

    dataBranch.forEach(val => {
      if (outlet === val.id) {
        name = `${val.id} - ${val.branch}`;
      }
    })
  
    return name;
  }

  const getPlanName = (plan) => {
    let name = "";
    if (plan === "ip_basic_plus_1") {
      name = "Instaprotection BASIC+ 1";
    }
    else if (plan === "ip_basic_plus_2") {
      name = "Instaprotection BASIC+ 2";
    }
    else if (plan === "ip_basic_plus_3") {
      name = "Instaprotection BASIC+ 3";
    }
    else if (plan === "ip_basic_plus_4") {
      name = "Instaprotection BASIC+ 4";
    }

    return name;
  }

  return (
    <>
      <Helmet>
        <title> Device | Advice </title>
      </Helmet>

      <Dialog
        open={openDetails}
        onClose={handleCloseDetails}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Registration Details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedItem ? <Grid style={{
              width: "100%", display: "flex",
              flexDirection: "row"
            }} ><Grid style={{
              width: "100%", display: "flex",
              flexDirection: "column", paddingRight: 30
            }}>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Register ID
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem && selectedItem.id ? selectedItem.id : "-"}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Register Date/Time
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 14 }}>{`${new Date(selectedItem.created * 1000).getFullYear()}
                -${new Date(selectedItem.created * 1000).getMonth() + 1}
  -${new Date(selectedItem.created * 1000).getDate()} ${new Date(selectedItem.created * 1000).getHours()}: ${new Date(selectedItem.created * 1000).getMinutes()}`}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Purchase Date
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.purchase_date}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Full Name
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.full_name}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Email
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.email}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  IMEI
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.imeino}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Plan
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {getPlanName(selectedItem.plan)}
                </Typography>


              </Grid>
              <Grid style={{
                width: "100%", display: "flex",
                flexDirection: "column"
              }}>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  City
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.city}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Mobile Number
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 14 }}>
                  {selectedItem.mobileNum ? selectedItem.mobileNum : "-"}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Home Address
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 14 }}>
                  {selectedItem.home_address ? selectedItem.home_address : "-"}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Serial No.
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.serialno}
                </Typography>

                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Salesman
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.sales_name}
                </Typography>


                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Outlet
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {getOutletName(selectedItem.outlet)}
                </Typography>


                <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  File
                </Typography>
                <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  <Link target='_blank' href={renderFilePath(selectedItem.file_fullPath)}>Link</Link>
                </Typography>


              </Grid>
            </Grid> : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen2}
        onClose={handleDialogClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Download Excel"}
        </DialogTitle>
        {dialogLoading ? <DialogContent>
          <CircularProgress color="inherit" />
        </DialogContent> : <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select the purchase date range
          </DialogContentText>
          <Grid style={{ width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)} />
              </DemoContainer>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)} />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </DialogContent>}
        {dialogLoading ? null : <DialogActions>
          <Button onClick={handleDialogClose2}>Cancel</Button>
          <Button onClick={handleSubmitDownload} autoFocus>
            Download
          </Button>
        </DialogActions>}
      </Dialog>

      <Dialog
        open={dialogOpen3}
        onClose={handleDialogClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Download Excel (Created Date)"}
        </DialogTitle>
        {dialogLoading ? <DialogContent>
          <CircularProgress color="inherit" />
        </DialogContent> : <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select the created date range
          </DialogContentText>
          <Grid style={{ width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)} />
              </DemoContainer>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)} />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </DialogContent>}
        {dialogLoading ? null : <DialogActions>
          <Button onClick={handleDialogClose3}>Cancel</Button>
          <Button onClick={handleSubmitDownloadCreatedDate} autoFocus>
            Download
          </Button>
        </DialogActions>}
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit"}
        </DialogTitle>
        <DialogContent>
          <FormControl style={{ marginTop: 20 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="Status"
              id="Status"
              value={status}
              label="Status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={"pending_verification"}>Pending Verification</MenuItem>
              <MenuItem value={"verified"}>Verified</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={handleDialogSubmit} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Device Registration
          </Typography>
          <Stack direction="row" alignItems="end" >
          {userRole === "super_admin" ? <Button variant="contained" style={{ marginRight: 10 }} onClick={downloadExcel}>
              {"Download Excel (Purchase Date)"}
            </Button> : null}
            {userRole === "super_admin" ? <Button variant="contained" style={{ marginRight: 10 }} onClick={downloadExcelCreatedDate}>
              {"Download Excel (Created Date)"}
            </Button> : null}
            {userRole === "super_admin" ? <Button variant="contained" href='/dashboard/bulk_upload_device'>
              Bulk Upload
            </Button> : null}

          </Stack>

        </Stack>

        <Card>
          <UserListToolbar
            handleChangeSelect={handleChangeSelect}
            select={select}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy2}
                  headLabel={TABLE_HEAD}
                  rowCount={row.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    let _countryName = "";

                    if (row.country && row.country === "srilanka") {
                      _countryName = "Sri Lanka"
                    }
                    else if (row.country && row.country === "myanmar") {
                      _countryName = "Myanmar"
                    }
                    else if (row.country && row.country === "brunei") {
                      _countryName = "Brunei"
                    }
                    else if (row.country && row.country === "nepal") {
                      _countryName = "Nepal"
                    }
                    else {
                      _countryName = row.country;
                    }

                    let _status = "";

                    if (row.status && row.status === "pending_verification") {
                      _status = "Pending Verification"
                    }
                    else if (row.status && row.status === "verified") {
                      _status = "Verified"
                    }


                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Typography align='left' style={{ fontSize: 14 }}>{`${new Date(row.created * 1000).getFullYear()}
                        -${new Date(row.created * 1000).getMonth() + 1}
                        -${new Date(row.created * 1000).getDate()}`}
                          </Typography>
                          <Typography align='left' style={{ fontSize: 14 }}>
                            {`${new Date(row.created * 1000).getHours()}: ${new Date(row.created * 1000).getMinutes()}`}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{row.purchase_date}</TableCell>

                        <TableCell align="left">{row.full_name}</TableCell>

                        <TableCell align="left">{row.imeino}</TableCell>

                        <TableCell align="left">{row.outlet}</TableCell>

                        <TableCell align="left">{getPlanName(row.plan)}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}


              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            component="div"
            count={row.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

        <MenuItem onClick={handleOnDetails} >
          <Iconify icon={'clarity:details-solid'} sx={{ mr: 2 }} />
          Details
        </MenuItem>

        <MenuItem onClick={handleOnEdit} >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

      </Popover>
    </>
  );
}
