// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBhHLgOLj4StzNAGDJ4el1pOPePWzt-w3g",
  authDomain: "advice-cf528.firebaseapp.com",
  databaseURL: "https://advice-cf528-default-rtdb.firebaseio.com",
  projectId: "advice-cf528",
  storageBucket: "advice-cf528.appspot.com",
  messagingSenderId: "835498848483",
  appId: "1:835498848483:web:1f1d60736063ffb5a215d6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


export default app;