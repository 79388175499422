import { Navigate, useRoutes } from 'react-router-dom';

import { Grid } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import Frontend from './layouts/frontend';

import Frontendth from './layouts/frontendth';
//

import SearchRegistration from './pages/SearchRegistration';
import ProtectionPlanRegistration from './pages/ProtectionPlanRegistration';
import TermAndConditions from './pages/TermAndConditions';
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';

import HomePageth from './pages/HomePageth';
import SearchRegistrationth from './pages/SearchRegistrationth';
import ProtectionPlanRegistrationth from './pages/ProtectionPlanRegistrationth';
import TermAndConditionsth from './pages/TermAndConditionsth';

import ImeiPage from './pages/ImeiPage';
import PurchasedImeiPage from './pages/PurchasedImeiPage';
import DevicePage from './pages/DevicePage';
import AllDevicePage from './pages/AllDevicePage';
import DownloadDataPage from './pages/DownloadDataPage';
import SearchDevicePage from './pages/SearchDevicePage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import ProductPage from './pages/ProductPage';
import DashboardAppPage from './pages/DashboardAppPage';

import NewUserPage from './pages/NewUserPage';
import NewUserPageAll from './pages/NewUserPageAll';
import NewSuperAdminPage from './pages/NewSuperAdminPage';
import NewPurchasedImei from './pages/NewPurchasedImei';
import NewProductPage from './pages/NewProductPage';
import NewImeiPage from './pages/NewImeiPage';
import NewDeviceRegistrationPage from './pages/NewDeviceRegistrationPage';

import BulkUploadProductPage from './pages/BulkUploadProductPage';
import BulkUploadImeiPage from './pages/BulkUploadImeiPage';
import BulkUploadDevicePage from './pages/BulkUploadDevicePage';

import { UserAuth } from './context/AuthContext'
// ----------------------------------------------------------------------

export default function Router() {

  const { user } = UserAuth()

  const routes = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'search-protection-care-plan', element: <SearchRegistration />},
        { path: 'register-protection-care-plan/:id', element: <ProtectionPlanRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        
      ],
    },
    {
      path: '/th',
      element: <Frontendth />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePageth /> },
        { path: 'search-protection-care-plan', element: <SearchRegistrationth />},
        { path: 'register-protection-care-plan/:id', element: <ProtectionPlanRegistrationth />},
        { path: 'terms-and-conditions', element: <TermAndConditionsth /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <Navigate to="/login" replace />,
    },
    {
      path: '/dashboard/*',
      element: <Navigate to="/login" replace />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const routesAdmin = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'search-protection-care-plan', element: <SearchRegistration />},
        { path: 'register-protection-care-plan/:id', element: <ProtectionPlanRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        
      ],
    },
    {
      path: '/th',
      element: <Frontendth />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePageth /> },
        { path: 'search-protection-care-plan', element: <SearchRegistrationth />},
        { path: 'register-protection-care-plan/:id', element: <ProtectionPlanRegistrationth />},
        { path: 'terms-and-conditions', element: <TermAndConditionsth /> },
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'new_user', element: <NewUserPage /> },
        { path: 'product', element: <ProductPage /> },
        { path: 'new_product', element: <NewProductPage /> },
        { path: 'bulk_upload_product', element: <BulkUploadProductPage /> },
        { path: 'imei', element: <ImeiPage /> },
        { path: 'new_imei', element: <NewImeiPage /> },
        { path: 'bulk_upload_imei', element: <BulkUploadImeiPage /> },
        { path: 'bulk_upload_device', element: <BulkUploadDevicePage /> },
        { path: 'device', element: <DevicePage /> },
        { path: 'new_device', element: <NewDeviceRegistrationPage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
        { path: 'all_device', element: <AllDevicePage /> },
        { path: 'download_data', element: <DownloadDataPage /> },
        { path: 'purchased_imei', element: <PurchasedImeiPage /> },
        { path: 'new_purchased_imei', element: <NewPurchasedImei /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);


  const routesEditor = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'search-protection-care-plan', element: <SearchRegistration />},
        { path: 'register-protection-care-plan/:id', element: <ProtectionPlanRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        
      ],
    },
    {
      path: '/th',
      element: <Frontendth />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePageth /> },
        { path: 'search-protection-care-plan', element: <SearchRegistrationth />},
        { path: 'register-protection-care-plan/:id', element: <ProtectionPlanRegistrationth />},
        { path: 'terms-and-conditions', element: <TermAndConditionsth /> },
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/device" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/device" />, index: true },
        { path: 'device', element: <DevicePage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const routesManager = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'search-protection-care-plan', element: <SearchRegistration />},
        { path: 'register-protection-care-plan/:id', element: <ProtectionPlanRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        
      ],
    },
    {
      path: '/th',
      element: <Frontendth />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePageth /> },
        { path: 'search-protection-care-plan', element: <SearchRegistrationth />},
        { path: 'register-protection-care-plan/:id', element: <ProtectionPlanRegistrationth />},
        { path: 'terms-and-conditions', element: <TermAndConditionsth /> },
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/device" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'new_user', element: <NewUserPage /> },
        { path: 'device', element: <DevicePage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  let _userRole = "";

  if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
    _userRole = JSON.parse(user.reloadUserInfo.customAttributes).role;
  }

  if(user === "none"){
    return (
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>
        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
        flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  if(user && _userRole === "super_admin")
  {
    return routesAdmin
  }

  if(user && _userRole === "editor")
  {
    return routesEditor
  }

  if(user && _userRole === "manager")
  {
    return routesManager
  }

  return routes;
}
