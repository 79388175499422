import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AodIcon from '@mui/icons-material/Aod';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, writeBatch, doc, Timestamp } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update, serverTimestamp } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { MuiFileInput } from 'mui-file-input'
import moment from 'moment';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyBhHLgOLj4StzNAGDJ4el1pOPePWzt-w3g",
  authDomain: "advice-cf528.firebaseapp.com",
  databaseURL: "https://advice-cf528-default-rtdb.firebaseio.com",
  projectId: "advice-cf528",
  storageBucket: "advice-cf528.appspot.com",
  messagingSenderId: "835498848483",
  appId: "1:835498848483:web:1f1d60736063ffb5a215d6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const rtdb = getDatabase(app);

// TODO remove, this demo shouldn't need to reset the theme.
export default function BulkUploadProductPage() {

  const [file, setFile] = React.useState(null);
  const [array, setArray] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowError, setRowError] = React.useState([]);
  const [wrongHeader, setWrongHeader] = React.useState([]);
  const [missingHeader, setMissingHeader] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fileReader = new FileReader();

  const handleOnChange = (newFile) => {
    setFile(newFile);

    if (newFile) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(newFile);
    }
  };

  const csvFileToArray = string => {

    const csvHeader = string.slice(0, string.indexOf("\n")).slice(0, string.indexOf("\r")).toLowerCase().replace(/\s/g,'').split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const header = ['name', 'capacity', 'color', 'partno', 'category', 'status']
    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    const _wrongHeader = []
    const _correctHeader = []
    const _missingHeader = []

    csvHeader.forEach(val=>{
     
      const currentIndex = header.map(val=>val.toLowerCase()).indexOf(val.toLowerCase())
      
      if(currentIndex !== -1){
        _correctHeader.push(val)
      }
      else{
        _wrongHeader.push(val)
      }
      
    })
    
    setWrongHeader(_wrongHeader)

    header.forEach(val=>{
      const currentIndex = csvHeader.map(val=>val.toLowerCase()).indexOf(val.toLowerCase())
      if(currentIndex === -1){
        _missingHeader.push(val)
      }
    })

    setMissingHeader(_missingHeader);
    
    const _array = []
    array.forEach((item, index)=>{
      
      const isEmpty = !Object.values(item).some(x => x !== undefined && x !== null && x !== '');
      if(!isEmpty){
        _array.push(item)
      }
    })

    const errorList = [];
    _array.forEach((item, index)=>{

      if(item.status){
        if(!(item.status.replace(/\s/g,'') === "active" || item.status.replace(/\s/g,'') === "inactive"))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }
      

      if(item.category){
        if(!(item.category.replace(/\s/g,'') === "iphone" 
        || item.category.replace(/\s/g,'') === "huawei"
        || item.category.replace(/\s/g,'') === "vivo"
        || item.category.replace(/\s/g,'') === "samsung"))
        {
          errorList.push(index + 1)
        }
      }
      else
      {
        errorList.push(index + 1)
      }
      
      if(item.partno){
        if(!(item.partno.replace(/\s/g,'').length > 0))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }

      if(item.color){
        if(!(item.color.replace(/\s/g,'').length > 0))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }

      if(item.capacity){
        if(!(item.color.replace(/\s/g,'').length > 0))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }
      

      if(item.name){
        if(!(item.name.replace(/\s/g,'').length > 0))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }
      

    })

    const uniqueErrorList = Array.from(new Set(errorList));
    
    setRowError(uniqueErrorList)
    setArray(_array);
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  

 

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    let count = 0;

    array.forEach((value, index)=>{

      setTimeout(async ()=> {
        // // console.log(index)

        value.id = value.partno.toUpperCase().replace('/','-')
        value.created = Timestamp.fromDate(new Date()).toMillis();

        const updates = {};
        updates[`product/${value.partno.toUpperCase().replace('/','-')}`] = value;
        update(dbref(rtdb), updates);

        count += 1;

        if(array.length === count)
        {
          setLoading(false);
          navigate('/dashboard/product')
        }

      }, index * 10);
      
    })

    /* array.forEach((value, index)=>{
      value.created = serverTimestamp();
      value.id = value.partno.toUpperCase().replace('/','-')
      const nycRef = doc(db, "product", `${value.partno.toUpperCase().replace('/','-')}`);
      batch.set(nycRef, value);
    })
    

    await batch.commit().then(()=>{
      setLoading(false);
      navigate('/dashboard/product')
    }) */

  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `product_csv_template.csv`;
    link.href = "/assets/template/product_csv_template.csv";
    link.click();
  };

  if (loading) {
    return (

      <Grid style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Grid style={{
          width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
          flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container component="main">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <AodIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Bulk Upload Product
        </Typography>
        <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={onDownload}
          >
            Download CSV format
          </Button>
        <Box component="form" noValidate onSubmit={handleSubmit} >
          <Grid container sx={{ mt:2, display: 'flex', width: "100%",
          flexDirection: 'column',
          alignItems: 'center' }}>
            <Grid item xs={12} sm={12}>
              <MuiFileInput color='primary'

                placeholder="Import CSV file"
                getInputText={(value) => value ? value.name : 'Import CSV file'}
                inputProps={{ accept: 'text/CSV' }}
                value={file} onChange={handleOnChange} />
            </Grid>
            
            {file ? <Grid item xs={12} sm={12}>
              <Paper sx={{ width: "90vw", maxWidth: 1000, overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 350 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                            key={"no"}
                          >
                            {"No."}
                          </TableCell>
                        {headerKeys.map((key) => 
                          {
                            let headerName = "";
                            if(key === "name"){
                              headerName = "Name";
                            }
                            else if(key === "partno"){
                              headerName = "Part No";
                            }
                            else if(key === "category"){
                              headerName = "Category";
                            }
                            else if(key === "status"){
                              headerName = "Status";
                            }
                            else if(key === "capacity"){
                              headerName = "Capacity";
                            }
                            else if(key === "color"){
                              headerName = "Color";
                            }
                            else{
                              headerName = key.charAt(0).toUpperCase() + key.slice(1);;
                            }

                            return(
                              <TableCell
                                key={key}
                              >
                                {headerName}
                              </TableCell>
                            )
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      
                        {array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => {

                          const currentIndex = rowError.map(val=>val).indexOf(index+1)

                          return (
                            <TableRow sx={{ backgroundColor: currentIndex !== -1 ? "#b21818": "white" }} role="checkbox" tabIndex={-1} key={index}>
                              <TableCell>
                                  {(index+1) + (page*rowsPerPage)}
                              </TableCell>

                              {Object.values(item).map((val) => (
                                <TableCell key={val + item.id + index }>
                                  {val}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={array.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>: null}

            <Grid item xs={12} sm={12} style={{ maxWidth: "70vw" }}>
                
                  {file && rowError.length > 0 ? <Typography sx={{ color: "red", wordWrap: "break-word"}} variant="body2">
                  {`No: ${rowError.join().split('|')} data incorrect`}
                 </Typography> : null}
                 {file && wrongHeader.length > 0 ? <Typography style={{ color: "red"}}  variant="body2">
                  {`Wrong Title: ${wrongHeader.join().split('|')}`}
                 </Typography> : null}
                 {file && missingHeader.length > 0 ? <Typography style={{ color: "red"}}  variant="body2">
                  {`Missing Title: ${missingHeader.join().split('|')}`}
                  </Typography> : null}

                  {file && (rowError.length > 0 || wrongHeader.length > 0 || missingHeader.length > 0)? <Typography style={{ color: "red"}}  variant="body2">
                  {"Status is a required field, please fill in active or inactive"}
                  </Typography> : null}

                  {file && (rowError.length > 0 || wrongHeader.length > 0 || missingHeader.length > 0)? <Typography style={{ color: "red"}}  variant="body2">
                  {"Category is a required field, please fill in iphone or huawei or vivo or samsung"}
                  </Typography> : null}

                  {file && (rowError.length > 0 || wrongHeader.length > 0 || missingHeader.length > 0)? <Typography style={{ color: "red"}}  variant="body2">
                  {"Name ,Part No, Capacity and Color field cannot be blank"}
                  </Typography> : null}
            </Grid>

          </Grid>
          {file ? <Button
            type="submit"
            fullWidth
            disabled={rowError.length > 0 || wrongHeader.length > 0 || missingHeader.length > 0}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Upload
          </Button> : null}

        </Box>
      </Box>

    </Container>
  );
}