import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AodIcon from '@mui/icons-material/Aod';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, setDoc, doc, serverTimestamp  } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyBhHLgOLj4StzNAGDJ4el1pOPePWzt-w3g",
  authDomain: "advice-cf528.firebaseapp.com",
  databaseURL: "https://advice-cf528-default-rtdb.firebaseio.com",
  projectId: "advice-cf528",
  storageBucket: "advice-cf528.appspot.com",
  messagingSenderId: "835498848483",
  appId: "1:835498848483:web:1f1d60736063ffb5a215d6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// TODO remove, this demo shouldn't need to reset the theme.
export default function NewProductPage() {

  const [status, setStatus] = React.useState('active');
  const [category, setCategory] = React.useState('iphone');
  
  const [productNameError, setProductNameError] = React.useState(false);
  const [partNoError, setPartNoError] = React.useState(false);
  const [capacityError, setCapacityError] = React.useState(false);
  const [colorError, setColorError] = React.useState(false);
  const [productList, setProductList] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let productNamePass = false;
    let partNoPass = false;
    let capacityPass = false;
    let colorPass = false;


    const data = new FormData(event.currentTarget);

    if(data.get('productName').length > 2){
      setProductNameError(false)
      productNamePass = true;
    }
    else
    {
      setProductNameError(true)
    }

    if(data.get('partno').length > 2){
      setPartNoError(false)
      partNoPass = true;
    }
    else
    {
      setPartNoError(true)
    }

    if(data.get('capacity').length > 2){
      setCapacityError(false)
      capacityPass = true;
    }
    else
    {
      setCapacityError(true)
    }

    if(data.get('color').length > 2){
      setColorError(false)
      colorPass = true;
    }
    else
    {
      setColorError(true)
    }

    if(productNamePass === true && partNoPass === true && capacityPass === true && colorPass === true)
    {
        setLoading(true);
        
        await setDoc(doc(db, "product", `${data.get('partno').toUpperCase().replace('/','-')}`), {
          name: data.get('productName').toUpperCase() || null,
          partno: data.get('partno').toUpperCase() || null,
          category: category || null,
          color: data.get('color').toUpperCase() || null,
          capacity: data.get('capacity').toUpperCase() || null,
          status: status || null,
          created: serverTimestamp(),
        }).then(()=>{
          setLoading(false);
          navigate('/dashboard/product')
        }).catch((e)=>{
          // console.log(e)
        })
    }


  };

  if(loading){
    return (
      
      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column"  }}>
        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
        flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AodIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            New Product
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  name="productName"
                  required
                  error={productNameError}
                  fullWidth
                  id="productName"
                  label="Product Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={partNoError}
                  id="partno"
                  label="Part No"
                  name="partno"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={capacityError}
                  id="capacity"
                  label="Capacity"
                  name="capacity"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={colorError}
                  id="color"
                  label="Color"
                  name="color"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label="Category"
                    onChange={handleChangeCategory}
                  >
                    <MenuItem value={"iphone"}>Iphone</MenuItem>
                    <MenuItem value={"vivo"}>Vivo</MenuItem>
                    <MenuItem value={"samsung"}>Samsung</MenuItem>
                    <MenuItem value={"huawei"}>Huawei</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Status"
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add Product
            </Button>

          </Box>
        </Box>

      </Container>
  );
}