import { Outlet } from 'react-router-dom';

import AppAppBarth from '../../modules/views/AppAppBarth';

import AppFooter from '../../modules/views/AppFooter';

export default function Frontend() {
  return (
    <>
      <AppAppBarth />
      <Outlet />
      <AppFooter/>
    </>
    
  );
}
