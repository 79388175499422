import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useHistory ,useLocation, useNavigate } from 'react-router-dom';

const drawerWidth = 240;
const navItems = [
  { 
    title: 'Home',
    path: '/th/home' 
  }, 
  {
    title: 'ค้นหาแบบประกัน',
    path: '/th/search-protection-care-plan' 
  }, 
  {
    title: 'ข้อกำหนดและเงื่อนไข',
    path: '/th/terms-and-conditions' 
  }];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [language, setlanguage] = React.useState('th');

  const navigate = useNavigate();
  const location = useLocation()

  const handleLanguageChange = (event) => {
    // navigate('/home');

    const languagename = location.pathname.split("/");

    if(languagename[1].length === 2){
      const pathname = location.pathname.substring(3);
      navigate(pathname);
    }

    setlanguage(event.target.value);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center'}}>
      <div style={{ flexGrow: 1, flexDirection: "row", display: "flex", padding: 30 }}>
          <img src='/assets/logo.svg' alt='advice logo' height={30}/>
          </div>
      <Divider />
      <List style={{ padding: 15 }} >
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton  href={item.path}  sx={{ textAlign: 'left' }}>
              <Typography style={{ fontWeight: 100, fontSize: 14 }}>
                  {item.title}
              </Typography>
            </ListItemButton>
            
          </ListItem>
        ))}
      </List>

    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" sx={{  display: "flex", alignItems: "center", backgroundColor: "black",boxShadow: 'none', borderBottom: ".5px solid rgb(57, 57, 60)" }} >
     
        <Toolbar sx={{ backgroundColor: "black", maxWidth: 1200, width: "100%"   }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          
          <div  style={{ flexGrow: 1, flexDirection: "row", display: "flex" }}>
          <Link href="/th/home"><img src='/assets/advice_logo.png' alt='advice logo' height={40}/></Link>
          </div>
          
          
          
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item.title} href={item.path} sx={{ color: '#cecece', fontWeight: 100 }}>
                {item.title}
              </Button>
            ))}
            
          </Box>

          <FormControl style={{ height: 30, paddingLeft: 15 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                style={{ color: "white", height: 30, fontSize: 12 }}
                onChange={handleLanguageChange}
              >
                <MenuItem style={{ height: 30, fontSize: 12 }} value={"en"}>English</MenuItem>
                <MenuItem style={{height: 30, fontSize: 12 }} value={"th"}>Thai</MenuItem>
              </Select>
            </FormControl>
          
        </Toolbar>
        
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      
    </Box>
    
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;