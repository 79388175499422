import { Helmet } from 'react-helmet-async';
import { useState,useEffect } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';
import Box from '@mui/material/Box';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, serverTimestamp, Timestamp, getDocs, collection, query, where } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";
import { countBy } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import countryList from 'react-select-country-list'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiFileInput } from 'mui-file-input'
import { getStorage, ref, uploadBytes } from "firebase/storage";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams  } from 'react-router-dom';

import moment from 'moment';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyBhHLgOLj4StzNAGDJ4el1pOPePWzt-w3g",
  authDomain: "advice-cf528.firebaseapp.com",
  databaseURL: "https://advice-cf528-default-rtdb.firebaseio.com",
  projectId: "advice-cf528",
  storageBucket: "advice-cf528.appspot.com",
  messagingSenderId: "835498848483",
  appId: "1:835498848483:web:1f1d60736063ffb5a215d6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const rtdb = getDatabase(app);

const plandata = [{
  id: "ip_basic_plus_1",
  name: "Instaprotection BASIC + 1",
  description: "Samsung/Huawei/Honor/Oppo/Xiaomi/ Poco/Redmi/Realme/Vivo",
  term: "ยกเว้น Samsung Fold / Samsung Flip /Samsung S24+/Samsung S24Ultra / Oppo Find N Flip /Xiaomi 12Pro/ Xiaomi 13 Pro/ Vivo NEX/ Vivo X Pro Series)"
},
{
  id: "ip_basic_plus_2",
  name: "Instaprotection BASIC + 2",
  description: "สำหรับ Apple iPhone X/ iPhone XR/ iPhone Xs/ iPhone XS Max",
  term: ""
},
{
  id: "ip_basic_plus_3",
  name: "Instaprotection BASIC + 3",
  description: "สำหรับ iPhone 11, 12 , 13, 14 ทุกรุ่น และ Samsung S23 Series (จอหน้า+กระจกหลัง)",
  term: "สำหรับ iPhone 11, 12,13,14 ราคาประกันนี้เฉพาะกระจกหน้าเท่านั้น"
},
{
  id: "ip_basic_plus_4",
  name: "Instaprotection BASIC + 4",
  description: "Samsung Fold / Samsung Flip /Samsung S24+/Samsung S24Ultra / Oppo Find N Flip /Xiaomi 12 Pro/ Xiaomi 13 Pro/ Vivo NEXI Vivo X Pro Series และ iPhone 15 ทุกรุ่น",
  term: ""
}];

const data = [
  {
      "id": "51000011",
      "branch": "Pantip Plaza Ngamwongwan",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000014",
      "branch": "IT Mall Fortune Town",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000018",
      "branch": "Imperial Samrong",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000028",
      "branch": "Central Rama 2",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000034",
      "branch": "Robinson Ratchaphruek ",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000038",
      "branch": "Central Westville",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000012",
      "branch": "Central Westgate",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000013",
      "branch": "Seacon Bang Khae",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000019",
      "branch": "Seacon Srinakarin",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000035",
      "branch": "Central World",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000021",
      "branch": " The Forrest",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000033",
      "branch": "Central Rama 3",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000017",
      "branch": "Zeer Rangsit",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000022",
      "branch": "Fashion Island",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000029",
      "branch": "Mega Bangna",
      "region": "AVI - BKK and Metro"
  },
  {
      "id": "51000002",
      "branch": "Prachin Buri",
      "region": "AVI - East"
  },
  {
      "id": "51000003",
      "branch": "Chachoengsao",
      "region": "AVI - East"
  },
  {
      "id": "51000008",
      "branch": "Chonburi/Atara Mall",
      "region": "AVI - East"
  },
  {
      "id": "51000037",
      "branch": "Chonburi/Harbor Mall Laem Chabang",
      "region": "AVI - East"
  },
  {
      "id": "51000009",
      "branch": "Tukcom Chonburi",
      "region": "AVI - East"
  },
  {
      "id": "51000026",
      "branch": "Chonburi/Ban Suan Intersection",
      "region": "AVI - East"
  },
  {
      "id": "51000010",
      "branch": "Chonburi/Pattaya",
      "region": "AVI - East"
  },
  {
      "id": "51000036",
      "branch": "Tukcom Pattaya",
      "region": "AVI - East"
  },
  {
      "id": "51000001",
      "branch": "Chanthaburi",
      "region": "AVI - East"
  },
  {
      "id": "51000031",
      "branch": "Central Chanthaburi",
      "region": "AVI - East"
  },
  {
      "id": "51000004",
      "branch": "Sa Kaeo",
      "region": "AVI - East"
  },
  {
      "id": "51000023",
      "branch": "Central Rayong",
      "region": "AVI - East"
  },
  {
      "id": "51000025",
      "branch": " Rayong",
      "region": "AVI - East"
  },
  {
      "id": "51000006",
      "branch": "Trat",
      "region": "AVI - East"
  },
  {
      "id": "52000045",
      "branch": "Lopburi/Sa Kaeo Circle",
      "region": "UNS - Central"
  },
  {
      "id": "52000046",
      "branch": "Lopburi/Phra Narai",
      "region": "UNS - Central"
  },
  {
      "id": "52000047",
      "branch": "Sing Buri",
      "region": "UNS - Central"
  },
  {
      "id": "52000050",
      "branch": "Chai Nat",
      "region": "UNS - Central"
  },
  {
      "id": "52000078",
      "branch": "Ayutthaya",
      "region": "UNS - Central"
  },
  {
      "id": "52000082",
      "branch": "Saraburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000083",
      "branch": "Suphan Buri",
      "region": "UNS - Central"
  },
  {
      "id": "52000001",
      "branch": "Nakhon Pathom",
      "region": "UNS - Central"
  },
  {
      "id": "52000092",
      "branch": "Central Nakhon Pathom",
      "region": "UNS - Central"
  },
  {
      "id": "52000048",
      "branch": "Kanchanaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000049",
      "branch": "Phetchaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000080",
      "branch": "Prachuap Khiri Khan",
      "region": "UNS - Central"
  },
  {
      "id": "52000085",
      "branch": "Ratchaburi",
      "region": "UNS - Central"
  },
  {
      "id": "52000084",
      "branch": "Central Mahachai",
      "region": "UNS - Central"
  },
  {
      "id": "52000003",
      "branch": "Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000094",
      "branch": "Icon IT Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000097",
      "branch": "Central Festival Chiang Mai",
      "region": "UNS - North"
  },
  {
      "id": "52000004",
      "branch": "Lamphun",
      "region": "UNS - North"
  },
  {
      "id": "52000006",
      "branch": "Chiang Rai",
      "region": "UNS - North"
  },
  {
      "id": "52000099",
      "branch": "Central Chiang Rai",
      "region": "UNS - North"
  },
  {
      "id": "52000007",
      "branch": "Chiang Rai/Mae Sai",
      "region": "UNS - North"
  },
  {
      "id": "52000008",
      "branch": "Lampang",
      "region": "UNS - North"
  },
  {
      "id": "52000009",
      "branch": "Phrea",
      "region": "UNS - North"
  },
  {
      "id": "52000010",
      "branch": "Nan",
      "region": "UNS - North"
  },
  {
      "id": "52000011",
      "branch": "Phayao",
      "region": "UNS - North"
  },
  {
      "id": "52000012",
      "branch": "Uttaradit",
      "region": "UNS - North"
  },
  {
      "id": "52000013",
      "branch": "Sukhothai",
      "region": "UNS - North"
  },
  {
      "id": "52000018",
      "branch": "Phichit",
      "region": "UNS - North"
  },
  {
      "id": "52000019",
      "branch": "Phichit/Taphan Hin",
      "region": "UNS - North"
  },
  {
      "id": "52000020",
      "branch": "Phitsanulok",
      "region": "UNS - North"
  },
  {
      "id": "52000061",
      "branch": "Phetchabun",
      "region": "UNS - North"
  },
  {
      "id": "52000015",
      "branch": "Kamphaeng Phet",
      "region": "UNS - North"
  },
  {
      "id": "52000016",
      "branch": "Nakhon Sawan",
      "region": "UNS - North"
  },
  {
      "id": "52000062",
      "branch": "Central Nakhon Sawan",
      "region": "UNS - North"
  },
  {
      "id": "52000017",
      "branch": "Uthai Thani",
      "region": "UNS - North"
  },
  {
      "id": "52000021",
      "branch": "Tak",
      "region": "UNS - North"
  },
  {
      "id": "52000052",
      "branch": "Krabi",
      "region": "UNS - South"
  },
  {
      "id": "52000058",
      "branch": "Nakhon Si Thammarat/Thung Song",
      "region": "UNS - South"
  },
  {
      "id": "52000059",
      "branch": "Nakhon Si Thammarat",
      "region": "UNS - South"
  },
  {
      "id": "52000088",
      "branch": "Central Nakhon Si Thammarat",
      "region": "UNS - South"
  },
  {
      "id": "52000090",
      "branch": "Trang",
      "region": "UNS - South"
  },
  {
      "id": "52000051",
      "branch": "Phatthalung",
      "region": "UNS - South"
  },
  {
      "id": "52000054",
      "branch": "Songkhla/Hat Yai",
      "region": "UNS - South"
  },
  {
      "id": "52000096",
      "branch": "Central Hat Yai",
      "region": "UNS - South"
  },
  {
      "id": "52000055",
      "branch": "Songkhla",
      "region": "UNS - South"
  },
  {
      "id": "52000093",
      "branch": "Satun",
      "region": "UNS - South"
  },
  {
      "id": "52000053",
      "branch": "Ranong",
      "region": "UNS - South"
  },
  {
      "id": "52000057",
      "branch": "Phuket",
      "region": "UNS - South"
  },
  {
      "id": "52000098",
      "branch": "Central Phuket Festival",
      "region": "UNS - South"
  },
  {
      "id": "52000060",
      "branch": "ชุมพร ",
      "region": "UNS - South"
  },
  {
      "id": "52000086",
      "branch": "Surat Thani",
      "region": "UNS - South"
  },
  {
      "id": "52000087",
      "branch": "Central Suratthani",
      "region": "UNS - South"
  },
  {
      "id": "52000100",
      "branch": "Pattani",
      "region": "UNS - South"
  },
  {
      "id": "52000101",
      "branch": "Narathiwat",
      "region": "UNS - South"
  },
  {
      "id": "52000024",
      "branch": "Udon Thani",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000069",
      "branch": "Tukcom Udon Thani",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000064",
      "branch": "Nong Khai",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000066",
      "branch": "Loei",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000027",
      "branch": "Nong Bua Lamphu",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000029",
      "branch": "Mukdahan",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000031",
      "branch": "Nakhon Phanom",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000032",
      "branch": "Kalasin",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000063",
      "branch": "Sakon Nakhon",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000067",
      "branch": "Sakon Nakhon/Phang Khon",
      "region": "UNS - Upper Northeast"
  },
  {
      "id": "52000028",
      "branch": "Khon Kaen/Chum Phae",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000034",
      "branch": "Khon Kaen",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000095",
      "branch": "Khon Kaen University",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000035",
      "branch": "Maha Sarakham",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000102",
      "branch": "Mahasarakham University",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000065",
      "branch": "Chaiyaphum",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000033",
      "branch": "Roi Et",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000036",
      "branch": "Ubon Ratchathani",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000077",
      "branch": "Ubon Ratchathani",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000037",
      "branch": "Amnat Charoen",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000089",
      "branch": "Yasothon",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000039",
      "branch": "Si Sa Ket",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000040",
      "branch": "Sisaket/Kantharalak",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000071",
      "branch": "Surin",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000043",
      "branch": "Buriram",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000044",
      "branch": "Buriram/Soi 5",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000072",
      "branch": "Nakhon Ratchasima",
      "region": "UNS - Lower Northeast"
  },
  {
      "id": "52000073",
      "branch": "Nakhon Ratchasima/IT Plaza",
      "region": "UNS - Lower Northeast"
  }
];

const brandList = [
  { id: "honor", name: "Honor" },
  { id: "huawei", name: "Huawei" },
  { id: "iphone", name: "iPhone" },
  { id: "oppo", name: "OPPO" },
  { id: "poco", name: "POCO" },
  { id: "realme", name: "Realme" },
  { id: "redmi", name: "Redmi" },
  { id: "samsung", name: "Samsung" },
  { id: "vivo", name: "Vivo" },
  { id: "xiaomi", name: "Xiaomi" }
]

const capacityList = [
  { id: "64gb", name: "64GB" },
  { id: "128gb", name: "128GB" },
  { id: "256gb", name: "256GB" },
  { id: "512gb", name: "512GB" },
  { id: "1tb", name: "1TB" }
]

// ----------------------------------------------------------------------

export default function ProtectionPlanRegistration() {

  const [fullname, setFullname] = React.useState('');
  const [clientId, setClientId] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [mobileNum, setMobileNum] = React.useState('');
  const [homeAddress, setHomeAddress] = React.useState('');
  const [deviceImei, setDeviceImei] = React.useState('');
  const [serialNumber, setSerialNumber] = React.useState('');
  const [agreement, setAgreement] = React.useState(false);
  const [date, setDate] = React.useState(dayjs());
  const [file, setFile] = React.useState(null);

  const [fullnameError, setFullnameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [deviceImeiError, setDeviceImeiError] = React.useState(false);
  const [serialNumberError, setSerialNumberError] = React.useState(false);
  const [clientIdError, setClientIdError] = React.useState(false);
  const [mobileNumError, setMobileNumError] = React.useState(false);
  const [fileInputError, setFileInputError] = React.useState(false);
  const [imeiRegistered, setImeiRegistered] = React.useState(false);
  const [serialNoRegistered, setSerialNoRegistered] = React.useState(false);
  const [imeiList, setImeiList] = React.useState([]);
  const [deviceRegList, setDeviceRegList] = React.useState([]);
  const [regID, setRegID] = React.useState("");


  

  const [alignment, setAlignment] = React.useState('nic');

  const [loading, setLoading] = React.useState(false);
  const [thankyou, setThankyou] = React.useState(false);

  const options = React.useMemo(() => countryList().getData(), [])

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [resellerNameError, setResellerNameError] = React.useState(false);
  const [resellerName, setResellerName] = React.useState('');

  const { id } = useParams();

  const [currentCountryCode, setCurrentCountryCode] = React.useState("");
  const [currentCountry, setCurrentCountry] = React.useState("");
  const [currentCountryName, setCurrentCountryName] = React.useState("");
  const [termName, setTermName] = React.useState("advice");

  const [protectionplan, setProtectionplan] = React.useState('ip_basic_plus_1');

  const [brand, setBrand] = React.useState('honor');
  const [capacity, setCapacity] = React.useState('128gb');
  const [deviceColor, setDeviceColor] = React.useState('');
  const [modelName, setModelName] = React.useState('');

  const [deviceColorError, setDeviceColorError] = React.useState(false);
  const [modelNameError, setModelNameError] = React.useState(false);

  const handleBrandChange = (event) => {
    setBrand(event.target.value)
  };

  const handleCapacityChange = (event) => {
    setCapacity(event.target.value)
  };

  const handleDeviceColorChange = (event) => {
    setDeviceColor(event.target.value)
  };

  const handleModelNameChange = (event) => {
    setModelName(event.target.value)
  };

  const handleProtectionplanChange = (event) => {
    setOpen2(true)
    setProtectionplan(event.target.value)
  };

  useEffect(() => {
    
    setCurrentCountryCode(id.substring(0, 1))
    setCurrentCountry(id)
    setCurrentCountryName(id.toUpperCase())
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOnChangeUploadfile = (newFile) => {
    setFile(newFile);
  };

  const handleChangeAgreement = (event) => {
    setAgreement(event.target.checked)
  };

  const handleChangeFullname = (event) => {
    setFullname(event.target.value)
  };

  const handleChangeClientId = (event) => {
    setClientId(event.target.value)
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
  };

  const handleChangeMobileNum = (event) => {
    setMobileNum(event.target.value)
  };

  const handleChangeHomeAddress = (event) => {
    setHomeAddress(event.target.value)
  };


  const handleChangeResellerName = (event) => {
    setResellerName(event.target.value)
  };

  const handleChangeDeviceImei = (event) => {
    setDeviceImei(event.target.value)
  };

  const handleChangeSerialNumber = (event) => {
    setSerialNumber(event.target.value)
  };

  const saveData = () => {
    setLoading(true);

    const storage = getStorage(app);
    const storageRef = ref(storage, `invoice/${Timestamp.fromDate(new Date()).toMillis()}_${file.name.replace(/\s/g,'')}`);

    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, file).then((snapshot) => {

      const registerID = `${currentCountryCode}${Timestamp.fromDate(new Date()).toMillis()}`;
      setRegID(registerID);
      try
      {

        set(dbref(rtdb, `device-registration/${deviceImei}`), {
          full_name: fullname || null,
          id: deviceImei,
          email: email || null,
          mobile: mobileNum,
          outlet: id,
          sales_name: resellerName || null,
          file_fullPath: snapshot.metadata.fullPath || null,
          file_contentType: snapshot.metadata.contentType || null,
          home_address: homeAddress || null,
          imeino: deviceImei || null,
          serialno: serialNumber ||  null,
          purchase_date: date.$d.toISOString().substring(0, 10) || null,
          created: Timestamp.fromDate(new Date()).seconds,
          status: "pending_verification",
          plan: protectionplan,
          
          brand: brand || null,
          capacity: capacity || null,
          model_name: modelName || null,
          device_color: deviceColor || null
        }).then(()=>{
          
          const emailID = `${currentCountryCode}${Timestamp.fromDate(new Date()).toMillis()}`;
          try
          {
            setDoc(doc(db, "mail", emailID), {
              to: email,
              message: {
                subject: 'Protection Care Plan Successful Registration',
                html: `<!DOCTYPE html>
                <html>
                <head>
                
                  <meta charset="utf-8">
                  <meta http-equiv="x-ua-compatible" content="ie=edge">
                  <title>Protection Care Plan Successful Registration</title>
                  <meta name="viewport" content="width=device-width, initial-scale=1">
                  <style type="text/css">
                  /**
                   * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
                   */
                  @media screen {
                    @font-face {
                      font-family: 'Source Sans Pro';
                      font-style: normal;
                      font-weight: 400;
                      src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
                    }
                    @font-face {
                      font-family: 'Source Sans Pro';
                      font-style: normal;
                      font-weight: 700;
                      src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
                    }
                  }
                  /**
                   * Avoid browser level font resizing.
                   * 1. Windows Mobile
                   * 2. iOS / OSX
                   */
                  body,
                  table,
                  td,
                  a {
                    -ms-text-size-adjust: 100%; /* 1 */
                    -webkit-text-size-adjust: 100%; /* 2 */
                  }
                  /**
                   * Remove extra space added to tables and cells in Outlook.
                   */
                  table,
                  td {
                    mso-table-rspace: 0pt;
                    mso-table-lspace: 0pt;
                  }
                  /**
                   * Better fluid images in Internet Explorer.
                   */
                  img {
                    -ms-interpolation-mode: bicubic;
                  }
                  /**
                   * Remove blue links for iOS devices.
                   */
                  a[x-apple-data-detectors] {
                    font-family: inherit !important;
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important;
                    color: inherit !important;
                    text-decoration: none !important;
                  }
                  /**
                   * Fix centering issues in Android 4.4.
                   */
                  div[style*="margin: 16px 0;"] {
                    margin: 0 !important;
                  }
                  body {
                    width: 100% !important;
                    height: 100% !important;
                    padding: 0 !important;
                    margin: 0 !important;
                  }
                  /**
                   * Collapse table borders to avoid space between cells.
                   */
                  table {
                    border-collapse: collapse !important;
                  }
                  a {
                    color: #1a82e2;
                  }
                  img {
                    height: auto;
                    line-height: 100%;
                    text-decoration: none;
                    border: 0;
                    outline: none;
                  }
                  </style>
                
                </head>
                <body style="background-color: #e9ecef;">
                
                  <!-- start preheader -->
                  <div class="preheader" style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
                  Thank you for completing your registration of your protection care plan for your device.
                  </div>
                  <!-- end preheader -->
                
                  <!-- start body -->
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                
                    <!-- start logo -->
                    <tr>
                      <td align="center" bgcolor="#e9ecef">
                        <!--[if (gte mso 9)|(IE)]>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                        <tr>
                        <td align="center" valign="top" width="600">
                        <![endif]-->
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                          <tr>
                            <td align="center" valign="top" style="padding: 36px 24px;">
                              <a href="https://advice.instaprotection.co.th/" target="_blank" style="display: inline-block;">
                                <img src="https://firebasestorage.googleapis.com/v0/b/advice-cf528.appspot.com/o/logo.png?alt=media&token=4211ea64-aff6-40e4-bdc6-097adf676f82" alt="Logo" border="0" width="150" style="display: block; width: 150px; max-width: 150px; min-width: 48px;">
                              </a>
                            </td>
                          </tr>
                        </table>
                        <!--[if (gte mso 9)|(IE)]>
                        </td>
                        </tr>
                        </table>
                        <![endif]-->
                      </td>
                    </tr>
                    <!-- end logo -->
                
                    <!-- start copy block -->
                    <tr>
                      <td align="center" bgcolor="#e9ecef">
                        <!--[if (gte mso 9)|(IE)]>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                        <tr>
                        <td align="center" valign="top" width="600">
                        <![endif]-->
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

                        <!-- start copy -->
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                              <p style="margin: 0;">Dear ${fullname},</p>
                            </td>
                          </tr>
                          <!-- end copy -->
                
                          <!-- start copy -->
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                              <p style="margin: 0;">Thank you for completing your registration of your protection care plan for your device.</p>
                            </td>
                          </tr>
                          <!-- end copy -->
                
                          <!-- start copy -->
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                              <p style="margin: 0;">Your coverage for the device is as follows:</p>
                              <p style="margin: 0; margin-top: 10">1. Extended Warranty Coverage From 
                              ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").add(1, "years").format("Do MMM YYYY")} to ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").add(2, "years").format("Do MMM YYYY")}</p>
                              <p style="margin: 0;">2. Screen Crack Coverage From 
                              ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").format("Do MMM YYYY")} to ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").add(1, "years").format("Do MMM YYYY")}</p>
                            </td>
                          </tr>
                          <!-- end copy -->
                
                          <!-- start copy -->
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                              <p style="margin: 0;">Cheers,<br> Advice </p>
                            </td>
                          </tr>
                          <!-- end copy -->
                
                        </table>
                        <!--[if (gte mso 9)|(IE)]>
                        </td>
                        </tr>
                        </table>
                        <![endif]-->
                      </td>
                    </tr>
                    <!-- end copy block -->
                
                    <!-- start footer -->
                    <tr>
                      <td align="center" bgcolor="#e9ecef" style="padding: 24px;">
                        <!--[if (gte mso 9)|(IE)]>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                        <tr>
                        <td align="center" valign="top" width="600">
                        <![endif]-->
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                
                          <!-- start permission -->
                          <tr>
                            <td align="center" bgcolor="#e9ecef" style="padding: 12px 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">
                              <p style="margin: 0;">This Care plan is underwritten by Advice. All Service Requests are subjected to the respective countries’ terms and conditions list <a href="https://advice.instaprotection.co.th/terms-and-conditions" target="_blank">here.</a></p>
                            </td>
                          </tr>
                          <!-- end permission -->
                
                
                        </table>
                        <!--[if (gte mso 9)|(IE)]>
                        </td>
                        </tr>
                        </table>
                        <![endif]-->
                      </td>
                    </tr>
                    <!-- end footer -->
                
                  </table>
                  <!-- end body -->
                
                </body>
                </html>`,
              }
            }).then(()=>{

              setLoading(false);
              setThankyou(true);

              setFullnameError(false);
              setEmailError(false);
              setResellerNameError(false);
              setDeviceImeiError(false);
              setSerialNumberError(false);
              setFileInputError(false);
              setMobileNumError(false);
              setClientIdError(false);
              setAlignment('nic');

              setFullname('');
              setClientId('');
              setEmail('');
              setHomeAddress('');
              setResellerName('');
              setDeviceImei('');
              setSerialNumber('');
              setDate(dayjs());
              setFile(null);
              setAgreement(false);

            })
          }
          catch(e)
          {
            // console.log(e)
          }
          
         
        })
      }
      catch(e)
      {
        // console.log(e)
      }
      
    });
  }

  const submitHandler = async (e) => {
    
    e.preventDefault();

    setLoading(true);

    let fullnamePass = false;
    let emailPass = false;
    let resellerNamePass = false;
    let imeiRegPass = false;
    let serialNumberPass = false;
    let mobileNumPass = false;
    let filePass = false;
    let modelNamePass = false;
    let deviceColorPass = false;

    
    if(file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf" )){
      setFileInputError(false)
      filePass = true;
    }
    else
    {
      setFileInputError(true)
    }

    if(fullname.length > 5){
      setFullnameError(false)
      fullnamePass = true;
    }
    else
    {
      setFullnameError(true)
    }

    if(mobileNum.length > 5){
      setMobileNumError(false)
      mobileNumPass = true;
    }
    else
    {
      setMobileNumError(true)
    }


    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(email.length > 0 && emailRegex.test(email)){
      setEmailError(false)
      emailPass = true;
    }
    else
    {
      setEmailError(true)
    }


    if(resellerName.length > 2){
      setResellerNameError(false)
      resellerNamePass = true;
    }
    else
    {
      setResellerNameError(true)
    }

    if(deviceImei.trim().length > 10){
      setImeiRegistered(false)
      imeiRegPass = true;
    }
    else
    {
      setImeiRegistered(true)
    }

    if(serialNumber.toUpperCase().trim().length > 5){
      setSerialNumberError(false)
      serialNumberPass = true;
    }
    else
    {
      setSerialNumberError(true)
    }

    if(modelName.length > 2){
      setModelNameError(false)
      modelNamePass = true;
    }
    else
    {
      setModelNameError(true)
    }

    if(deviceColor.length > 2){
      setDeviceColorError(false)
      deviceColorPass = true;
    }
    else
    {
      setDeviceColorError(true)
    }

    if(fullnamePass === true && 
      emailPass === true && 
      mobileNumPass === true &&
      filePass === true &&
      imeiRegPass === true &&
      resellerNamePass === true &&
      modelNamePass === true &&
      deviceColorPass === true &&
      serialNumberPass === true && agreement === true)
    {
        
      setOpen(true);
      setLoading(false);
        
    }
    else{
      setLoading(false);
    }

  }

  

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  if(loading){
    return (
      
      <Grid style={{ width: "100%",display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }

  if(thankyou){
    return(
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
         flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Typography align='center' style={{ fontWeight: 700, fontSize: 36 }}>
          Thank You For Your Registration.
          </Typography>
          <Typography align='center' style={{ fontSize: 12, marginTop: 15 }}>
          An email with the details about your registration has been sent to the email address provided.
          </Typography>
          <Typography align='center' style={{ fontSize: 12, marginTop: 5 }}>
          Please keep it for your records.
          </Typography>

          <Grid style={{ width: "100%", maxWidth: 500, height: 2, borderBottom: '1px solid white', opacity: .5, marginTop: 20, marginBottom: 10  }}/>
          

          <Typography align='center' style={{ fontSize: 12, marginTop: 15 }}>
          We're dedicated to giving you the best experience possible. 
          </Typography>
          <Typography align='center' style={{ fontSize: 12, marginTop: 5 }}>
          If you have any questions, feel free to get in touch.
          </Typography>
          <Button  variant="contained" style={{ marginTop: 20, backgroundColor: "red" }} href={`/register-protection-care-plan/${id}`}   disableElevation>
          Back
          </Button>
        </Grid>
      </Grid>

    )
  }

  const getOutletName = (outlet) => {
    let name = "";

    data.forEach(val => {
      if (outlet === val.id) {
        name = `${val.id} - ${val.branch}`;
      }
    })
  
    return name;
  }

  const getPlanName = (plan) => {
    let name = "";
    if(plan === "ip_basic_plus_1"){
      name = "Instaprotection BASIC+ 1";
    }
    else if(plan === "ip_basic_plus_2"){
      name = "Instaprotection BASIC+ 2";
    }
    else if(plan === "ip_basic_plus_3"){
      name = "Instaprotection BASIC+ 3";
    }
    else if(plan === "ip_basic_plus_4"){
      name = "Instaprotection BASIC+ 4";
    }
  
    return name;
  }

  const renderplandata = () => {
    let data = ""
    if(protectionplan === "ip_basic_plus_1")
    {
      data = plandata[0].description
    }
    else if(protectionplan === "ip_basic_plus_2")
    {
      data = plandata[1].description
    }
    else if(protectionplan === "ip_basic_plus_3")
    {
      data = plandata[2].description
    }
    else if(protectionplan === "ip_basic_plus_4")
    {
      data = plandata[3].description
    }
    
    return data;
  }

  const renderplanterm = () => {
    let data = ""
    if(protectionplan === "ip_basic_plus_1")
    {
      data = plandata[0].term
    }
    else if(protectionplan === "ip_basic_plus_2")
    {
      data = plandata[1].term
    }
    else if(protectionplan === "ip_basic_plus_3")
    {
      data = plandata[2].term
    }
    else if(protectionplan === "ip_basic_plus_4")
    {
      data = plandata[3].term
    }
    
    return data;
  }

  const renderplantitle = () => {
    let data = ""
    if(protectionplan === "ip_basic_plus_1")
    {
      data = plandata[0].name
    }
    else if(protectionplan === "ip_basic_plus_2")
    {
      data = plandata[1].name
    }
    else if(protectionplan === "ip_basic_plus_3")
    {
      data = plandata[2].name
    }
    else if(protectionplan === "ip_basic_plus_4")
    {
      data = plandata[3].name
    }
    
    return data;
  }

  return (
    <>
      <Helmet>
        <title> {`${getOutletName(id)} | Protection Plan Registration`} </title>
      </Helmet>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to submit this form?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Grid style={{ width: "100%", display: "flex",
            flexDirection: "column" }}>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Full Name: ${fullname || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Outlet: ${getOutletName(id) || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Mobile Number: ${mobileNum || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Email: ${email || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Home Address: ${homeAddress || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`IMEI No: ${deviceImei || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Serial No: ${serialNumber || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Brand: ${brand || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Capacity: ${capacity || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Model Name: ${modelName || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Device Color: ${deviceColor || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Purchase Date: ${date ? date.$d.toISOString().substring(0, 10) : ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Attached File: ${file ? file.name : ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Protection Plan: ${getPlanName(protectionplan)}`}
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={saveData} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {renderplantitle()}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {renderplandata()}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
          <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          {renderplanterm()}
          </Typography>
          </DialogContentText>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleClose2} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, 
         flexDirection: "column", alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 30 }}>
          {`${getOutletName(id)}  | Protection Plan Registration`}
            </Typography>

            <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '30ch' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={submitHandler}
    >
      <Typography style={{ fontWeight: 700, fontSize: 18, marginTop: 30 }}>
      Personal Information
            </Typography>
      <Grid >
        <Grid container style={{ width: "100%"}} spacing={2}>

          <Grid item xs={12} md={6}>
            <TextField
            value={fullname}
            onChange={handleChangeFullname}
            style={{ margin: 0, marginTop: 15,width: "100%" }}
              required
              error={fullnameError}
              id="outlined-required"
              label="Full Name"
              type='text'
            />
          </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                id="outlined-number"
                label="Mobile Number"
                type="number"
                value={mobileNum}
                error={mobileNumError}
                onChange={handleChangeMobileNum}
              />
              <Typography style={{ marginTop: 10 , fontSize: 11, color: 'grey', fontStyle:"italic" }}>
          EXAMPLE: +66812345678
          </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                required
                value={email}
                error={emailError}
                onChange={handleChangeEmail}
                type='email'
                id="outlined-required"
                label="Email Address"
              />
            </Grid>

          <Grid item xs={12}>
              <TextField
              value={homeAddress}
              onChange={handleChangeHomeAddress}
              style={{ margin: 0, marginTop: 15, width: "100%" }}
              id="outlined-required"
              label="Home Address"
              rows={4}
              multiline
            />
          </Grid>

            <Grid item xs={12} md={6} >
                <TextField
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                required
                id="outlined-helperText"
                label="Salesman"
                value={resellerName}
                error={resellerNameError}
                onChange={handleChangeResellerName}
              />
            </Grid>

        </Grid>
        
        
        
      </Grid>
      <Typography style={{ fontWeight: 700, fontSize: 18, marginTop: 30 }}>
      Add Device Information
            </Typography>
      <Grid container style={{ width: "100%"}} spacing={2}>
        <Grid item xs={12} md={6} >
          <TextField
            style={{ margin: 0, marginTop: 15, width: "100%" }}
            required
              id="outlined-helperText"
              label="Device IMEI"
              value={deviceImei}
              type="number"
              error={(deviceImeiError || imeiRegistered)}
              onChange={handleChangeDeviceImei}
            />
            {imeiRegistered ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          This IMEI length too short.
          </Typography> :null}
        </Grid>
       
        <Grid item xs={12} md={6} >
          <TextField
         style={{ margin: 0, marginTop: 15, width: "100%" }}
          required
              id="outlined-helperText"
              label="Serial Number"
              inputProps={{ style: { textTransform: "uppercase" } }}
              value={serialNumber}
              error={(serialNumberError || serialNoRegistered)}
              onChange={handleChangeSerialNumber}
            />
          {serialNumberError ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          Serial Number length too short.
          </Typography> : null }
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl style={{ margin: 0, marginTop: 15, width: "100%" }}>
            <InputLabel id="brand-label">Brand</InputLabel>
            <Select
              labelId="brand-label"
              id="brand-label"
              value={brand}
              label="Brand"
              onChange={handleBrandChange}
            >
              {brandList.map((val, index) => {
                return  <MenuItem key={`brand_${index}`} value={val.id}>{val.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl style={{ margin: 0, marginTop: 15, width: "100%" }}>
            <InputLabel id="capacity-label">Capacity</InputLabel>
            <Select
              labelId="capacity-label"
              id="capacity-label"
              value={capacity}
              label="Capacity"
              onChange={handleCapacityChange}
            >
              {capacityList.map((val, index) => {
                return  <MenuItem key={`capacity_${index}`} value={val.id}>{val.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4} >
          <TextField
         style={{ margin: 0, marginTop: 15, width: "100%" }}
          required
              id="outlined-helperText"
              label="Device Model"
              inputProps={{ style: { textTransform: "uppercase" } }}
              value={modelName}
              error={(modelNameError)}
              onChange={handleModelNameChange}
            />
          {modelNameError ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          Model Name Invalid
          </Typography> : null }
        </Grid>
        
        <Grid item xs={12} md={4} >
          <TextField
         style={{ margin: 0, marginTop: 15, width: "100%" }}
          required
              id="outlined-helperText"
              label="Device Color"
              inputProps={{ style: { textTransform: "uppercase" } }}
              value={deviceColor}
              error={(deviceColorError)}
              onChange={handleDeviceColorChange}
            />
          {deviceColorError ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
         Device Color Invalid
          </Typography> : null }
        </Grid>

        <Grid item xs={12} md={4} style={{ paddingLeft: 7, paddingTop: 25 }}>
          <LocalizationProvider sx={{ width: "100%"}}  dateAdapter={AdapterDayjs}>
            <DatePicker 
              label="Purchase Date"
              value={date}
              onChange={(newDate) => setDate(newDate)}
              sx={{ width: "100%"}}  />
          </LocalizationProvider>
        </Grid>

        < Grid item xs={12} md={4} style={{ paddingLeft: 7, paddingTop: 25 }}>
            <MuiFileInput color='primary'
            required
            error={fileInputError}
            placeholder="Upload Invoice/Receipt*"
            getInputText={(value) => value ? value.name : 'Upload Invoice/Receipt*'}
            inputProps={{ accept: 'image/jpeg,image/png,application/pdf' }}
            value={file} onChange={handleOnChangeUploadfile} />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl style={{ margin: 0, marginTop: 15, width: "100%" }}>
            <InputLabel id="plan-selection-label">Protection Plan</InputLabel>
            <Select
              labelId="plan-selection-label"
              id="plan-selection-label"
              value={protectionplan}
              label="Protection Plan"
              onChange={handleProtectionplanChange}
            >
              <MenuItem value={"ip_basic_plus_1"}>Instaprotection BASIC+ 1</MenuItem>
              <MenuItem value={"ip_basic_plus_2"}>Instaprotection BASIC+ 2</MenuItem>
              <MenuItem value={"ip_basic_plus_3"}>Instaprotection BASIC+ 3</MenuItem>
              <MenuItem value={"ip_basic_plus_4"}>Instaprotection BASIC+ 4</MenuItem>
            </Select>
          </FormControl>
        </Grid>

      </Grid>

      <Grid style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: 30  }}>
      <FormGroup>
        <FormControlLabel required control={<Checkbox onChange={handleChangeAgreement} checked={agreement}  />} label={
          <Grid style={{ display: "flex", flexDirection: "column" }}>
            <Typography style={{ fontWeight: 700, fontSize: 14 }}>
              {`I agree to the (${termName})`}
            </Typography>
            <Link href="/terms-and-conditions" target='_blank'>
        <Typography style={{ fontWeight: 700, fontSize: 14 }}>
        Terms and Conditions *
            </Typography>

            {agreement ? null : <Typography style={{ fontWeight: 100, fontSize: 10, color: 'red' }}>
        This field is required
            </Typography> }

      </Link>
          </Grid>} />
        
      </FormGroup>
      
      
      </Grid>
      <Button type="submit" variant="contained" style={{ marginTop: 30, backgroundColor: "red" }}  disableElevation>
            Submit Your Registration
          </Button>
    </Box>


            
        </Grid>

       
        
      </Grid>
    </>
  );
}
