import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import * as React from 'react';
// @mui
import { Stack, Typography, Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Carousel } from '@angelplayer/react-carousel';

// ----------------------------------------------------------------------

const slides = [
    { image: '/assets/images/banners/banner-1.jpg', url: '#' },
    { image: '/assets/banner/banner1.png', url: '#' },
    { image: '/assets/banner/banner2.png', url: '#' },
    { image: '/assets/banner/banner3.png', url: '#' },
    { image: '/assets/banner/banner4.png', url: '#' },
    
];

const useStyles = makeStyles({
  select: {
      '&:before': {
          borderColor: 'white',
      },
      '&:after': {
          borderColor: 'white',
      },
      '&:not(.Mui-disabled):hover::before': {
          borderColor: 'white',
      },
  },
  icon: {
      fill: 'white',
  },
  root: {
      color: 'white',
  },
})

const data = [
    {
        "id": "51000011",
        "branchth": "พันธุ์ทิพย์ พลาซ่างามวงศ์วาน",
        "branchen": "Pantip Plaza Ngamwongwan",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000014",
        "branchth": "ไอทีมอลล์ ฟอร์จูนทาวน์",
        "branchen": "IT Mall Fortune Town",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000018",
        "branchth": "อิมพีเรียลสำโรง",
        "branchen": "Imperial Samrong",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000028",
        "branchth": "เซ็นทรัล พระราม 2",
        "branchen": "Central Rama 2",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000034",
        "branchth": "โรบินสัน สาขาราชพฤกษ์",
        "branchen": "Robinson Ratchaphruek ",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000038",
        "branchth": "เซ็นทรัล เวสต์วิลล์",
        "branchen": "Central Westville",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000012",
        "branchth": "เซ็นทรัล เวสต์เกต",
        "branchen": "Central Westgate",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000013",
        "branchth": "ซีคอน บางแค",
        "branchen": "Seacon Bang Khae",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000019",
        "branchth": "ซีคอน ศรีนครินทร์",
        "branchen": "Seacon Srinakarin",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000035",
        "branchth": "เซ็นทรัลเวิลด์",
        "branchen": "Central World",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000021",
        "branchth": "โครงการ The Forrest",
        "branchen": " The Forrest",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000033",
        "branchth": "เซ็นทรัลพระราม 3",
        "branchen": "Central Rama 3",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000017",
        "branchth": "เซียร์รังสิต",
        "branchen": "Zeer Rangsit",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000022",
        "branchth": "แฟชั่น ไอส์แลนด์",
        "branchen": "Fashion Island",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000029",
        "branchth": "เมกา บางนา",
        "branchen": "Mega Bangna",
        "regionen": "AVI - BKK and Metro",
        "regionth": "AVI - กรุงเทพและปริมณฑล"
    },
    {
        "id": "51000002",
        "branchth": "ปราจีนบุรี",
        "branchen": "Prachin Buri",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000003",
        "branchth": "ฉะเชิงเทรา",
        "branchen": "Chachoengsao",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000008",
        "branchth": "ชลบุรี/อาทาระมอลล์",
        "branchen": "Chonburi/Atara Mall",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000037",
        "branchth": "ชลบุรี/ฮาร์เบอร์มอลล์ แหลมฉบัง",
        "branchen": "Chonburi/Harbor Mall Laem Chabang",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000009",
        "branchth": "ตึกคอม ชลบุรี",
        "branchen": "Tukcom Chonburi",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000026",
        "branchth": "ชลบุรี/แยกบ้านสวน",
        "branchen": "Chonburi/Ban Suan Intersection",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000010",
        "branchth": "ชลบุรี/พัทยา",
        "branchen": "Chonburi/Pattaya",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000036",
        "branchth": "ตึกคอมพัทยา",
        "branchen": "Tukcom Pattaya",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000001",
        "branchth": "จันทบุรี",
        "branchen": "Chanthaburi",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000031",
        "branchth": "เซ็นทรัล จันทบุรี",
        "branchen": "Central Chanthaburi",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000004",
        "branchth": "สระแก้ว",
        "branchen": "Sa Kaeo",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000023",
        "branchth": "เซ็นทรัล ระยอง",
        "branchen": "Central Rayong",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000025",
        "branchth": "ระยอง",
        "branchen": " Rayong",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "51000006",
        "branchth": "ตราด",
        "branchen": "Trat",
        "regionen": "AVI - East",
        "regionth": "AVI - ภาคตะวันออก"
    },
    {
        "id": "52000045",
        "branchth": "ลพบุรี/วงเวียนสระแก้ว",
        "branchen": "Lopburi/Sa Kaeo Circle",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000046",
        "branchth": "ลพบุรี/พระนาราย",
        "branchen": "Lopburi/Phra Narai",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000047",
        "branchth": "สิงห์บุรี",
        "branchen": "Sing Buri",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000050",
        "branchth": "ชัยนาท ",
        "branchen": "Chai Nat",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000078",
        "branchth": "พระนครศรีอยุธยา",
        "branchen": "Ayutthaya",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000082",
        "branchth": "สระบุรี",
        "branchen": "Saraburi",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000083",
        "branchth": "สุพรรณบุรี ",
        "branchen": "Suphan Buri",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000001",
        "branchth": "นครปฐม",
        "branchen": "Nakhon Pathom",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000092",
        "branchth": "เซ็นทรัล นครปฐม",
        "branchen": "Central Nakhon Pathom",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000048",
        "branchth": "กาญจนบุรี ",
        "branchen": "Kanchanaburi",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000049",
        "branchth": "เพชรบุรี ",
        "branchen": "Phetchaburi",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000080",
        "branchth": "ประจวบคีรีขันธ์",
        "branchen": "Prachuap Khiri Khan",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000085",
        "branchth": "ราชบุรี",
        "branchen": "Ratchaburi",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000084",
        "branchth": "เซ็นทรัล มหาชัย",
        "branchen": "Central Mahachai",
        "regionen": "UNS - Central",
        "regionth": "UNS - ภาคกลาง"
    },
    {
        "id": "52000003",
        "branchth": "เชียงใหม่ ",
        "branchen": "Chiang Mai",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000094",
        "branchth": "ไอคอน ไอที เชียงใหม่",
        "branchen": "Icon IT Chiang Mai",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000097",
        "branchth": "เซ็นทรัลเฟสติวัล เชียงใหม่ ",
        "branchen": "Central Festival Chiang Mai",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000004",
        "branchth": "ลำพูน",
        "branchen": "Lamphun",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000006",
        "branchth": "เชียงราย",
        "branchen": "Chiang Rai",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000099",
        "branchth": "เซ็นทรัลเชียงราย",
        "branchen": "Central Chiang Rai",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000007",
        "branchth": "เชียงราย/แม่สาย",
        "branchen": "Chiang Rai/Mae Sai",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000008",
        "branchth": "ลำปาง",
        "branchen": "Lampang",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000009",
        "branchth": "แพร่",
        "branchen": "Phrea",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000010",
        "branchth": "น่าน",
        "branchen": "Nan",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000011",
        "branchth": "พะเยา",
        "branchen": "Phayao",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000012",
        "branchth": "อุตรดิตถ์",
        "branchen": "Uttaradit",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000013",
        "branchth": "สุโขทัย",
        "branchen": "Sukhothai",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000018",
        "branchth": "พิจิตร",
        "branchen": "Phichit",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000019",
        "branchth": "พิจิตร/ตะพานหิน",
        "branchen": "Phichit/Taphan Hin",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000020",
        "branchth": "พิษณุโลก",
        "branchen": "Phitsanulok",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000061",
        "branchth": "เพชรบูรณ์",
        "branchen": "Phetchabun",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000015",
        "branchth": "กำแพงเพชร",
        "branchen": "Kamphaeng Phet",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000016",
        "branchth": "นครสวรรค์ ",
        "branchen": "Nakhon Sawan",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000062",
        "branchth": "เซ็นทรัลนครสวรรค์",
        "branchen": "Central Nakhon Sawan",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000017",
        "branchth": "อุทัยธานี",
        "branchen": "Uthai Thani",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000021",
        "branchth": "ตาก",
        "branchen": "Tak",
        "regionen": "UNS - North",
        "regionth": "UNS - ภาคเหนือ"
    },
    {
        "id": "52000052",
        "branchth": "กระบี่",
        "branchen": "Krabi",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000058",
        "branchth": "นครศรีธรรมราช/ทุ่งสง",
        "branchen": "Nakhon Si Thammarat/Thung Song",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000059",
        "branchth": "นครศรีธรรมราช ",
        "branchen": "Nakhon Si Thammarat",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000088",
        "branchth": "เซ็นทรัล นครศรีธรรมราช",
        "branchen": "Central Nakhon Si Thammarat",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000090",
        "branchth": "ตรัง ",
        "branchen": "Trang",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000051",
        "branchth": "พัทลุง",
        "branchen": "Phatthalung",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000054",
        "branchth": "สงขลา/หาดใหญ่",
        "branchen": "Songkhla/Hat Yai",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000096",
        "branchth": "เซ็นทรัล หาดใหญ่",
        "branchen": "Central Hat Yai",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000055",
        "branchth": "สงขลา ",
        "branchen": "Songkhla",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000093",
        "branchth": "สตูล",
        "branchen": "Satun",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000053",
        "branchth": "ระนอง ",
        "branchen": "Ranong",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000057",
        "branchth": "ภูเก็ต ",
        "branchen": "Phuket",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000098",
        "branchth": "เซ็นทรัล ภูเก็ต เฟสติวัล",
        "branchen": "Central Phuket Festival",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000060",
        "branchth": "ชุมพร ",
        "branchen": "ชุมพร ",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000086",
        "branchth": "สุราษฎร์ธานี ",
        "branchen": "Surat Thani",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000087",
        "branchth": "เซ็นทรัล สุราษฎร์ธานี",
        "branchen": "Central Suratthani",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000100",
        "branchth": "ปัตตานี",
        "branchen": "Pattani",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000101",
        "branchth": "นราธิวาส",
        "branchen": "Narathiwat",
        "regionen": "UNS - South",
        "regionth": "UNS - ภาคใต้"
    },
    {
        "id": "52000024",
        "branchth": "อุดรธานี ",
        "branchen": "Udon Thani",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000069",
        "branchth": "ตึกคอมอุดรธานี",
        "branchen": "Tukcom Udon Thani",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000064",
        "branchth": "หนองคาย ",
        "branchen": "Nong Khai",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000066",
        "branchth": "เลย ",
        "branchen": "Loei",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000027",
        "branchth": "หนองบัวลำภู  ",
        "branchen": "Nong Bua Lamphu",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000029",
        "branchth": "มุกดาหาร ",
        "branchen": "Mukdahan",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000031",
        "branchth": "นครพนม ",
        "branchen": "Nakhon Phanom",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000032",
        "branchth": "กาฬสินธุ์ ",
        "branchen": "Kalasin",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000063",
        "branchth": "สกลนคร ",
        "branchen": "Sakon Nakhon",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000067",
        "branchth": "สกลนคร/พังโคน",
        "branchen": "Sakon Nakhon/Phang Khon",
        "regionen": "UNS - Upper Northeast",
        "regionth": "UNS - ภาคอีสานบน"
    },
    {
        "id": "52000028",
        "branchth": "ขอนแก่น/ชุมแพ ",
        "branchen": "Khon Kaen/Chum Phae",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000034",
        "branchth": "ขอนแก่น ",
        "branchen": "Khon Kaen",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000095",
        "branchth": "มหาวิทยาลัยขอนแก่น",
        "branchen": "Khon Kaen University",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000035",
        "branchth": "มหาสารคาม",
        "branchen": "Maha Sarakham",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000102",
        "branchth": "มหาวิทยาลัยมหาสารคาม",
        "branchen": "Mahasarakham University",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000065",
        "branchth": "ชัยภูมิ",
        "branchen": "Chaiyaphum",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000033",
        "branchth": "ร้อยเอ็ด ",
        "branchen": "Roi Et",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000036",
        "branchth": "อุบลราชธานี",
        "branchen": "Ubon Ratchathani",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000077",
        "branchth": "อุบลราชธานี",
        "branchen": "Ubon Ratchathani",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000037",
        "branchth": "อำนาจเจริญ",
        "branchen": "Amnat Charoen",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000089",
        "branchth": "ยโสธร",
        "branchen": "Yasothon",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000039",
        "branchth": "ศรีสะเกษ ",
        "branchen": "Si Sa Ket",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000040",
        "branchth": "ศรีสะเกษ/กันทรลักษ์",
        "branchen": "Sisaket/Kantharalak",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000071",
        "branchth": "สุรินทร์ ",
        "branchen": "Surin",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000043",
        "branchth": "บุรีรัมย์",
        "branchen": "Buriram",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000044",
        "branchth": "บุรีรัมย์/ซอย 5 ",
        "branchen": "Buriram/Soi 5",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000072",
        "branchth": "นครราชสีมา ",
        "branchen": "Nakhon Ratchasima",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    },
    {
        "id": "52000073",
        "branchth": "นครราชสีมา /ไอที พลาซ่า",
        "branchen": "Nakhon Ratchasima/IT Plaza",
        "regionen": "UNS - Lower Northeast",
        "regionth": "UNS - ภาคอีสานใต้"
    }
]

const dataRegion = [{
    id: "AVI - BKK and Metro",
    name: "AVI - BKK and Metro",
    nameth: "AVI - กรุงเทพและปริมณฑล"
  },
  {
    id: "AVI - East",
    name: "AVI - East",
    nameth: "AVI - ภาคตะวันออก"
  },
  {
    id: "UNS - Central",
    name: "UNS - Central",
    nameth: "UNS - ภาคกลาง"
  },
  {
    id: "UNS - North",
    name: "UNS - North",
    nameth: "UNS - ภาคเหนือ"
  },
  {
    id: "UNS - South",
    name: "UNS - South",
    nameth: "UNS - ภาคใต้"
  },
  {
    id: "UNS - Upper Northeast",
    name: "UNS - Upper Northeast",
    nameth: "UNS - ภาคอีสานบน"
  },
  {
    id: "UNS - Lower Northeast",
    name: "UNS - Lower Northeast",
    nameth: "UNS - ภาคอีสานใต้"
  }]

export default function HomePage() {

  const [outlet, setOutlet] = React.useState('51000001');
  const [region, setRegion] = React.useState('AVI - East');
  const classes = useStyles()

  const handleChange = (event) => {
    setOutlet(event.target.value);
  };

  const handleRegionChange = (event) => {
    const _data = data.sort((a, b) => a.id- b.id).filter(val => val.regionen === event.target.value);
    setOutlet(_data[0].id)
    setRegion(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Home |Advice </title>
      </Helmet>

      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column", paddingBottom: 80  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column", alignItems: "center" }}>
          <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#cecece' }}>
          ลงทะเบียนแผนประกัน
            </Typography>

          <Typography align="center" style={{ fontWeight: 700, fontSize: 36, color: '#cecece', marginTop: 15 }}>
          Mobile
          </Typography>
          
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "black" }}>
          <Grid style={{ backgroundColor: "black", padding: 15, paddingBottom: 0, width: 900 }}>
          <Carousel slides={slides} speed='5000' type='contain' />
          </Grid>
        </Grid>
        

        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60,paddingBottom: 80,
          flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
           <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#cecece' }}>
           ลงทะเบียนแผนประกัน
            </Typography>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "white", flexDirection: "column" }}>
          <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#151515', marginTop: 20 }}>
          ปกป้องทรัพย์สินมีค่าด้วยแผนประกันของเรา
          </Typography>
          <Grid style={{ display: "flex", justifyContent: "center", backgroundColor: "white", padding: 20 }}>
            <img src="/assets/images/banners/banner2-1.jpg" alt='banner2' width={860} />
          </Grid>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60, paddingBottom: 80,
          flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
          <Typography style={{ fontWeight: 700, fontSize: 36, color: '#cecece' }}>
          ค้นหาแบบประกัน
          </Typography>
          <Typography style={{ fontWeight: 100, fontSize: 14, color: '#cecece' }}>
          คุณสามารถค้นหาแผนประกันได้ทุกเวลาที่ต้องการที่นี่ ค้นหาแผนประกันตอนนี้
          </Typography>
          <Button variant="contained" style={{ marginTop: 20, backgroundColor: "red" }} href={"/th/search-protection-care-plan"}   disableElevation>
          ค้นหาแบบประกัน
          </Button>
        </Grid>


        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60,
          flexDirection: "column",  alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 36, color: '#cecece' }}>
          ลงทะเบียนแผนประกัน
          </Typography>
          <Typography style={{ fontWeight: 100, fontSize: 14, color: '#cecece' }}>
          ลงทะเบียนแผนประกันสำหรับโทรศัพท์ของคุณโดยเลือกตามสาขาที่ซื้อเครื่อง
          </Typography>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", paddingBottom: 20 }}>
          <Grid style={{ width: 300 }}>
            <FormControl fullWidth >
                
                <InputLabel id="demo-simple-select-label" sx={{ color: "white"}}>Region</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={region}
                  label="Region"
                  sx={{

                    height: '3.5rem',
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white'
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'white'
                    }
                    
                    
                }}
                  onChange={handleRegionChange}
                >
                {dataRegion.sort((a, b) => a.id- b.id).map(val=> {
                  return <MenuItem value={val.id}>{`${val.nameth}`}</MenuItem>
                })}
                </Select>

              </FormControl>
              <FormControl fullWidth  style={{ marginTop: 20 }}>
                
                <InputLabel id="demo-simple-select-label" sx={{ color: "white"}}>Outlet</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={outlet}
                  label="Outlet"
                  sx={{

                    height: '3.5rem',
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white'
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'white'
                    }
                    
                    
                }}
                  onChange={handleChange}
                >
                {data.sort((a, b) => a.id- b.id).filter(val => val.regionen === region).map(val=> {

                  return <MenuItem value={val.id}>{`${val.id} - ${val.branchth}`}</MenuItem>
                  
                })}
                </Select>

                <Button variant="contained" style={{ marginTop: 20, backgroundColor: "red" }} href={`/th/register-protection-care-plan/${outlet}`}   disableElevation>
                ลงทะเบียน
                </Button>
              </FormControl>
            </Grid>
        </Grid>

        
      </Grid>
    </>
  );
}
