import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

export default function TermAndConditions() {

  const submitHandler = (e) => {
    // console.log('submit called');
    e.preventDefault();
  }

  const [alignment, setAlignment] = React.useState('nic');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Helmet>
        <title> ข้อกำหนดและเงื่อนไข </title>
      </Helmet>

      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, 
         flexDirection: "column", alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 30, color: '#cecece' }}>
          ข้อกำหนดและเงื่อนไข
            </Typography>
            <Typography style={{ fontWeight: 100, fontSize: 14, color: '#cecece', padding: 20, paddingTop: 0 }}>
            กดเลือกผลิตภัณฑ์เพื่อดูข้อกำหนดและเงื่อนไข
          </Typography>

            
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "black", marginTop: 50 }}>
          <Grid style={{ backgroundColor: "black", padding: 15, paddingBottom: 0 }}>
            <img src="/assets/images/banners/banner-1.jpg" alt='banner1' width={800}/>
          </Grid>
        </Grid>
        

       
        
      </Grid>
    </>
  );
}
